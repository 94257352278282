import React, {useEffect, useState} from 'react';
import style from '../../styles/contacts.module.css'
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import {toJS} from "mobx";

const Contacts = () => {

    const [isName,setIsName] = useState('')
    const [isMail,setIsMail] = useState('')
    const [isMessage,setIsMessage] = useState('')
    const OnclickSend =()=>{
        toast.promise(
            axios.post(`${process.env.REACT_APP_API_URL}/api/contact?name=${isName}&email=${isMail}&message=${isMessage}`,{
                name:isName,
                email:isMail,
                message:isMessage
            })
            , {}



        )
     .then((data)=>{
         toast.success(data.data.message, {
             position: "bottom-right",
                 autoClose: 5000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
                 progress: undefined,
                 theme: "colored",
             style:{background:'white',color:'#1437ad'}

         })
         setIsName('')
         setIsMail('')
         setIsMessage('')
     })
            .catch((data)=>{


                const names = data?.response?.data?.message

                    toast.error(names, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",


                    });



            })

    }

    useEffect(() => {
        document.title ='Электромир — Контакты';
    }, []);



    return (
       <div className={style.contacts_container} >
           <div>
        <h1 className={style.h1}>Контакты</h1>
        <div className={style.contacts} >

            <div className={style.container}>
            <div className={style.container_input}>
                <input onChange={(e)=>setIsName(e.target.value)} className={style.input_name} value={isName} placeholder='Имя' type="text"/>
                <input onChange={(e)=>setIsMail(e.target.value)} className={style.input_mail}  value={isMail} placeholder='Email' type="text"/>
                <textarea onChange={(e)=>setIsMessage(e.target.value)} className={style.input_text} value={isMessage} placeholder='Текст сообщения...' type="text"/>
                <button style={{cursor:'pointer'}} onClick={OnclickSend} className={style.btn}>Отправить</button>
                <ToastContainer position="bottom-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"

                />

            </div>

                <div className={style.container_text}>
                    <h1 className={style.h1_text}>Остались вопросы? Напишите нам письмо!</h1>
                    <div>
                        <p className={style.title}>Режим работы:</p>
                        <p className={style.p}>Пн-Пт: 09.00 - 18.00;</p>
                        <p className={style.p}>Сб: 09.00 – 14.00;</p>
                        <p className={style.p}>Вс: выходной;</p>
                        <p className={style.p} ><span className={style.title}>Адрес магазина:</span> Респ. Саха (Якутия), г. Якутск, ул. Ойунского, 11, 1 этаж.</p>
                        <p className={style.p}><span className={style.title}>Контактный телефон:</span> <a style={{textDecoration:'none',color:'#1437ad'}}  href="tel:+79143053987"> +7-914-305-39-87  <span style={{fontWeight:'400'}}></span></a></p>
                        <p className={style.p}><span className={style.title}>E-mail:</span> <a style={{textDecoration:"none",color:'#1437ad'}} href="mailto:contact@elektromir-ykt.ru">contact@elektromir-ykt.ru</a></p>
                    </div>
                </div>

            </div>


        </div>
           </div>
       </div>

    );
};

export default Contacts;