import React, {useEffect, useState} from 'react';
import style from '../../styles/cartStyle.module.css'
import itemStore from "../../Store/ItemStore";
import {observer} from "mobx-react-lite";
import CartItem from "./CartItem";
import {Link, useParams} from "react-router-dom";
import delivery from '../../icon/delivery.png'
import payment from '../../icon/payment.png'
import axios from "axios";
import {toJS} from "mobx";
import {toast, ToastContainer} from "react-toastify";
import async from "async";

const Cart = () => {
    const {totalAmount, totalPrice, cart,removeAll} = itemStore;
    let params = useParams();
    let total = 0

    cart.forEach((item) => {
        total += item.regular_price * item.amount
    })
    const [name, setName] = useState('')
    const [mail, setMail] = useState('')
    const [phone, setPhone] = useState('')
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')



    // const sberRequest = async (orderId) => {
    //     try {
    //         const response = await axios.get(`https://3dsec.sberbank.ru/payment/rest/register.do?userName=t143500314009-api&password=1Qiuqb5G&orderNumber=${orderId}&amount=${total}&returnUrl=https://dev.elektromir-ykt.ru/cart&failUrl=https://dev.elektromir-ykt.ru/cart&dynamicCallbackUrl=https://06d96840-47d7-42fc-9ddd-0967f69681b2.mock.pstmn.io` ,null,{
    //             headers: {
    //                 'Content-Type': 'application/x-www-form-urlencoded'
    //             }
    //         })
    //
    //         return response;
    //     }catch (e) {
    //         alert(e.response.data.message)
    //     }
    // }
    // const sberRequest = async (orderId) => {
    // const params = new URLSearchParams();
    // params.append('userName', 't143500314009-api');
    // params.append('password', '1Qiuqb5G');
    // params.append('orderNumber', orderId);
    // params.append('amount', total);
    // params.append('returnUrl', 'https://dev.elektromir-ykt.ru/cart');
    // params.append('failUrl', 'https://dev.elektromir-ykt.ru/cart');
    // params.append('dynamicCallbackUrl', 'https://06d96840-47d7-42fc-9ddd-0967f69681b2.mock.pstmn.io');
    //
    // const config = {
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     }
    // };
    //
    // const response = await axios.get('https://3dsec.sberbank.ru/payment/rest/register.do', params, config);
    // }

    const postForm  =  () => {
        const order = cart.map(item => {
            return {slug: item.slug, amount: item.amount}
        })
        axios.post(`${process.env.REACT_APP_API_URL}/api/orders`, {
            customer_name: name,
            phone: phone,
            email: mail,
            city: city,
            address: address,
            order_items:order

        })
            .then( ({data}) => {

                    window.location.replace(data.paymentUrl)

                 toast.success(data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    style:{background:'white',color:'#1437ad'}

                })
                setName('')
                setMail('')
               setPhone('')
                setCity('')
                 setAddress('')



            })
            .catch((data) =>{
                const names = data?.response?.data?.message

                toast.error(names, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });


            })
            .finally(()=>{

            })
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        document.title = 'Электромир —  Корзина';
    }, [])


    return (
        cart.length === 0 ? <h1 className={style.cart_null}>В корзине пусто</h1>
            :
            <div className={style.cart}>
                <h1 className={style.cart_title}>Корзина</h1>
                <div className={style.cart_container}>
                    <div className={style.cart_list}>
                        {cart.map((item) => {
                            return (
                                <CartItem key={item.slug} data={item}/>
                            )
                        })}
                    </div>
                    <div className={style.total}>
                        <h1 className={style.cart_title}>Итого:</h1>
                        <div className={style.total_content}>
                            <div>{cart.length} <span>товара</span></div>
                            <div>{total} ₽</div>
                        </div>
                        <a href={'#payment'} style={{cursor: 'pointer'}} className={style.total_button}>Оформить
                            заказ </a>
                    </div>

                </div>
                <div className={style.delivery}>
                    <h1 className={style.MakingAnOrder_h1}>Доставка</h1>
                    <div className={style.delivery_container}>
                        <img style={{padding: ' 8px 0 0 0',width:'62px',height:'62px'}} src={delivery} alt=""/>
                        <div className={style.delivery_text}>
                            <p className={style.delivery_p}>Доставка осуществляется через сервис inDriver.</p>
                            <p className={style.delivery_p}> С условиями доставки ознакомтесь на странице <Link to={'/posts/uslovia-dostavki'}
                                style={{color: '#1437ad'}}>«Доставка».</Link></p>
                        </div>
                    </div>
                </div>
                <div className={style.payment}>
                    <h1 className={style.MakingAnOrder_h1}>Оплата</h1>
                    <div className={style.delivery_container}>
                        <img style={{padding: ' 8px 0 0 0',width:'62px',height:'62px'}} src={payment} alt=""/>
                        <div className={style.delivery_text}>
                            <p  className={style.delivery_p}>После оформления заказа вы будете
                                перенаправлены
                                на страницу оплаты.</p>

                        </div>
                    </div>
                </div>
                <div id={'payment'} className={style.MakingAnOrder}>
                    <h1 className={style.MakingAnOrder_h1}>Оформление заказа</h1>
                    <div className={style.MakingAnOrder_container}>
                        <div className={style.MakingAnOrder_container_form}>
                            <form action="">
                                <div className={style.MakingAnOrder_title}>Ваше ФИО *</div>
                                <input onChange={(e) => setName(e.target.value)} className={style.MakingAnOrder_input}
                                       placeholder={'Имя'} type="text" value={name}/>
                            </form>
                            <form action="">
                                <div className={style.MakingAnOrder_title}>E-mail *</div>
                                <input onChange={(e) => setMail(e.target.value)} className={style.MakingAnOrder_input}
                                       placeholder={'Электронная почта'} type="text" value={mail}/>
                            </form>
                            <form action="">
                                <div className={style.MakingAnOrder_title}>Телефон *</div>
                                <input onChange={(e) => setPhone(e.target.value)} className={style.MakingAnOrder_input}
                                       placeholder={'Номер телефона'} type="text" value={phone}/>
                            </form>
                            <form action="">
                                <div className={style.MakingAnOrder_title}>Город *</div>
                                <input onChange={(e) => setCity(e.target.value)} className={style.MakingAnOrder_input}
                                       placeholder={'Город'} type="text" value={city}/>
                            </form>
                            <form action="">
                                <div className={style.MakingAnOrder_title}>Адрес *</div>
                                <input onChange={(e) => setAddress(e.target.value)}
                                       className={style.MakingAnOrder_input} placeholder={'Адрес'} type="text"
                                       value={address}/>
                            </form>
                        </div>
                        {/*<Link to={'/paymentError'}>*/}
                        <button onClick={postForm} className={style.MakingAnOrder_button}> Подтвердить</button>
                        {/*</Link>*/}
                    </div>

                </div>
                <ToastContainer position="bottom-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"

                />
            </div>
    );
};

export default observer(Cart);