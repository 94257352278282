import React, {useEffect, useState} from 'react';
import itemStore from "../../Store/ItemStore";

import Alltem from "../Category/Alltem";

import {observer} from "mobx-react-lite";
import Loader from "../Loader/Loader";

const FilterSection = () => {
    const {SearchData,isLoadingSearch} = itemStore



    return (
        <div className='filter_item'>{
            SearchData.length === 0 ? <h1 className='filter_null'>Ничего не найдено</h1>
                :
                isLoadingSearch ? [...new Array(SearchData.length)].map((_,index)=><Loader key={index}/>) :
                        SearchData.map((item, index)=>{
                            return  <Alltem   data={item} key={item.id} />
                        })

        }

        </div>
    );
};

export default observer(FilterSection);