import React, {useEffect, useState} from 'react';
import OneCategory from "./oneCategory";
import itemStore from "../../Store/ItemStore";
import {Link} from "react-router-dom";
import BurgerOneCategory from "./burgerOneCategory";


const BurgerMenu = () => {

    const {getDataCategories,categories} = itemStore

    const  [isShowMenu,setIsShowMenu] =useState(false)
    useEffect(()=>{
        getDataCategories()
    },[])
  const close = ()=>{
      setIsShowMenu(false)
  }

    return (
        <>
            <div  onClick={()=>setIsShowMenu(!isShowMenu)} className='burger'>
                <span></span>
            </div>
            <div style={{display:'flex',position:'absolute',top:'62px',left:'0',width:'100vw'}}>
            <div   className={!isShowMenu ? `category1` : 'category_active1'}>
                <div onClick={close} className='burger_href'><Link to={'/catalog'}>Каталог товаров</Link>
                    <Link to={'/contacts'}>Контакты</Link>
                    <Link style={{textDecoration:"none"}} to={'/posts/uslovia-dostavki'}>Условие доставки</Link>
                    <Link to={'/posts'}>Блог</Link>
                </div>
               <div style={{maxHeight:'500px',overflow:'scroll'}}>
                {categories.map((item,index)=>{
                    return(


                        <BurgerOneCategory close={close} key={item.name} data={item}  index={index}/>



                    )


                })}

               </div>
            </div>
                <div onClick={close} className={!isShowMenu ? 'hi' : 'vis'} ></div>
            </div>
        </>
    );
};

export default BurgerMenu;