import React, {useEffect, useState} from 'react';
import ModalCategory from "./ModalCategory";
import {Link} from "react-router-dom";
import itemStore from "../../Store/ItemStore";
import {toJS} from "mobx";
import BurgerChildCategory from "./BurgerChildCategory";
import style from "../../styles/category.module.css";
import AttCheck from "../FilterSection/Attribute/AttributeCheckbox/AttCheck";

const BurgerOneCategory = ({data,index,close}) => {
    const [showChildrenFilter,setShowChildrenFilter] = useState(false);
    const [activeModalCat,setActiveModalCat]= useState(false)
const [activeModalNumber,setActiveModalNumber] = useState(0)

const {categories} = itemStore
const showModalCategory =(id)=>{
    setActiveModalNumber(id)
    setActiveModalCat(!activeModalCat)
}

    return (
        <>

            <div className={style.burger_section}>
                {data?.child_categories.length === 0 ? <div style={{marginLeft:'25px'}}></div> :   <li onClick={() => setShowChildrenFilter(!showChildrenFilter)} className={showChildrenFilter ? style.burger_li_active : style.burger_li} ></li>}
                <Link onClick={close} style={{textDecoration:"none",color: '#4E4E4E'}} to={`/catalog/${data.path}`}>
                <div  style={{cursor:'pointer'}}>{data?.name}</div>
                </Link>
            </div>
            {data.child_categories.length !== 0 ? <div onClick={()=>showModalCategory(index)} className={showChildrenFilter ? style.burger_param_active : style.burger_param} >
                {data.child_categories.map((item,index)=>{
                    return(   <BurgerChildCategory key={item.name} close={close}  data={item}  /> )

                })}



            </div> : ''}
        </>

    );
};

export default BurgerOneCategory;

// const [activeModalCat,setActiveModalCat]= useState(false)
// const [activeModalNumber,setActiveModalNumber] = useState(0)
//
// const {categories} = itemStore
// const showModalCategory =(id)=>{
//     setActiveModalNumber(id)
//     setActiveModalCat(!activeModalCat)
// }


// <>
//     <ul  onClick={()=>showModalCategory(index)} onMouseOut={()=>setActiveModalCat(false)}  className='burger_path' >
//         {data.child_categories.length !== 0 ?  <BurgerChildCategory setActiveModalCat={setActiveModalCat} name={data}  data={data.child_categories} active={activeModalCat} id={activeModalNumber}/> : ''}
//         <li >
//
//             <div style={{width:'260px'}}>
//                 <div  className= 'category_title'  >{data.name}</div>
//             </div>
//
//         </li>
//
//     </ul>
//
//
// </>