import React, {useEffect, useState} from 'react';
import ModalCategory from "./ModalCategory";
import itemStore from "../../Store/ItemStore";
import {observer} from "mobx-react-lite";

import OneCategory from "./oneCategory";

const Category = () => {

    const {getDataCategories,categories} = itemStore

    useEffect(()=>{
        getDataCategories()

    },[])


    return (
        <>

                <div  className='category'>

                    {categories.map((item,index)=>{
                        return(
                            <OneCategory key={item.name} data={item} index={index}/>
                        )


                    })}



                </div>


        </>
    );
};

export default observer(Category);