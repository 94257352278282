import React, {useState} from 'react';
import styles from '../../styles/category.module.css'
import {toJS} from "mobx";
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import itemStore from "../../Store/ItemStore";
import ModalChildCategory from "./ModalChildCategory";


const ModalCategory = ({active, setActive, data}) => {
const {categories} = itemStore
    const [activeChildsModal , setActiveChildModal] = useState(false)
    const [num,setNum] = useState(0)
    const [value,setvalue] = useState(null)

     const show =(id)=>{
    setNum(id)
    setActiveChildModal(true)
    setvalue(id)
     }

     const reset = () =>{
         setActiveChildModal(false)
         setvalue(null)
     }

    return (
        <div className={active ? `categoryModal active` : `categoryModal`}>

            {/*<Link style={{textDecoration:"none"}} to={`/catalog/${categories[id]?.path}`}>*/}
            {/*<div className={styles.categoryModal_title}>{categories[id]?.name}</div>*/}
            {/*    </Link>*/}

            <div className={styles.subCategory}>

                {data.map((item, index) => {
                    return (
                        <div  key={item.name} onMouseOver={()=>show(index)} onMouseOut={reset} className={styles.child}>

                                {num === index  && item.child_categories.length !==0 ?   <ModalChildCategory  key={item.name} reset={reset}  index={index}  active={activeChildsModal} setActive={setActiveChildModal}  data={item}/> : '' }


                        <Link  className='ModalCategory_link_child' to={`/catalog/${item?.path}`}>
                            <div style={{width: '350px'}}>
                            <div    className={styles.catalog_arrow}>
                                <div className={styles.childs_arrow}>
                                    {item.child_categories.length !== 0 ?   <li
                                        className={index  === value ? styles.catalog_li_active :  styles.catalog_li}></li> : ''}

                            </div>
                                <div className={styles.child_span}  onMouseOver={()=>show(index)} >{item?.name}</div>

                            </div>
                            </div>
                        </Link>
                        </div>
                        )

                })}


            </div>
        </div>

    );
};

export default observer(ModalCategory);