import React, {useEffect, useState} from 'react';
import style from '../../styles/discountedProducts.module.css'
import {observer} from "mobx-react-lite";
import itemStore from "../../Store/ItemStore";
import DiscountProductItems from "./DiscountProductItems";
import Loader from "../Loader/Loader";
import ScrollContainer from "react-indiana-drag-scroll";

const DiscountedProducts = () => {

    const {getDataDesc,DescData,categories,getDataCategories, isLoadingDesc}=itemStore


    const [activeIndex, setActiveIndex] = useState(0)

    const onClickBtn = (index) => {
        setActiveIndex(index);
        const category = categories[index].path;
        getDataDesc(category)
    }

    useEffect( () => {

             getDataCategories();
           getDataDesc(categories[0]?.slug);



    }, [])



    return (
        <div className={style.discountedProducts}>
            <h1 className={style.discountedProducts_title}> Товары со скидкой</h1>
            <div className={style.discountedProducts_container}>

                <div className={style.discountedProducts_category}>
                    <ScrollContainer style={{display:"flex",gap:'30px'}}>
                    {categories.map((item, index) => {
                        return (<button style={{cursor:"pointer"}}  key={index} onClick={()=>onClickBtn(index)}
                            className={style.discountedProducts_btn && (activeIndex === index) ? style['active'] : '' }>{item.name}</button>)
                    })}
                    </ScrollContainer>
                </div >
                <div className={style.discountedProducts_container_item} >
                    {isLoadingDesc ? [...new Array(4)].map((_, index)=><Loader key={index}/>) :
                        DescData.map((item,index)=>{
                            if(index<4){
                                return(
                                    <DiscountProductItems  key={index} data={item}/>
                                )
                            }
                        })}

                </div>

            </div>
        </div>
    );
};

export default observer(DiscountedProducts);