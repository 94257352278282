import React from 'react';
import {Link} from "react-router-dom";
import logo from '../../icon/1437ad.svg'

const Footer = () => {
    return (
        <div className='Footer'>
          <div className='footer_container'>
              <div>
                  <div className='footer_logo'><img src={logo} alt=""/></div>
                  <div className='footer_text'>Добро пожаловать! Мы рады приветствовать вас в нашем интернет-магазине Электромир. Здесь вы найдете все необходимое для электротехнических работ - от кабелей и проводки до электроустановочных изделий и теплотехники. Наша команда постоянно работает над тем, чтобы предложить вам самые качественные и доступные товары. </div>
              </div>
              <div style={{display:'flex',flexDirection:"column"}}>
                  <div className='footer_title'>
                  Популярные категории
                    </div>
                  <a style={{textDecoration:"none"}} href='catalog/elektrotehnika' className='footer_text'>Электротехника</a>
                  <a  style={{textDecoration:"none"}} href='catalog/svetotehnika' className='footer_text'>Светотехника</a>
                  <a style={{textDecoration:"none"}} href='catalog/elementy-pitaniya' className='footer_text'>Элементы питания</a>
                  <a style={{textDecoration:"none"}} href='catalog/instrumenty' className='footer_text'>Инструменты</a>
                  <a style={{textDecoration:"none"}} href='catalog/teplotehnika' className='footer_text'>Теплотехника</a>
              </div>
              <div>
                  <div   className='footer_title'>
                  Покупателям
              </div>
                  <Link style={{textDecoration:"none"}} to={'/catalog'}>
                  <div className='footer_text'>Каталог товаров</div>
                  </Link>
                  <Link style={{textDecoration:"none"}} to={'/posts/uslovia-dostavki'}>
                  <div className='footer_text'>Условия доставки</div>
                  </Link>
                  <Link style={{textDecoration:"none"}} to={'/posts'}>
                  <div className='footer_text'>Блог</div>
                  </Link>
              </div>
              <div>
                  <div   className='footer_title'>
                      Контакты
              </div>
                  <div  className='footer_text'><a style={{textDecoration:"none"}} href="tel:+79143053987"> +7-914-305-39-87  </a></div>
                  <div className='footer_text'>   <Link style={{textDecoration:"none"}} to={'/contacts'}> г. Якутск, ул. Ойунского, 11, 1 этаж. </Link> </div>
                  <div className='footer_text'><a style={{textDecoration:"none"}} href="mailto:contact@elektromir-ykt.ru">contact@elektromir-ykt.ru</a></div>
              </div>
          </div>
        </div>
    );
};

export default Footer;