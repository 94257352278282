import React, {useEffect, useState} from 'react';
import ModalCategory from "./ModalCategory";
import {Link} from "react-router-dom";
import itemStore from "../../Store/ItemStore";
import {toJS} from "mobx";

const OneCategory = ({data,index}) => {
    const [activeModalCat,setActiveModalCat]= useState(false)
    const [activeModalNumber,setActiveModalNumber] = useState(0)

    const {categories} = itemStore
    const showModalCategory =(id)=>{
        setActiveModalNumber(id)
        setActiveModalCat(true)
    }



    //
    return (
        <>
            <ul onMouseOver={()=>showModalCategory(index)} onMouseOut={()=>setActiveModalCat(false)}   >
                {data.child_categories.length !== 0 ?  <ModalCategory  data={data.child_categories} active={activeModalCat} id={activeModalNumber}/> : ''}
                <li >
                    <Link style={{textDecoration:"none"}} to={`/catalog/${data.path}`}>
                        <div style={{width:'260px'}}>
                        <div  className='category_title' >{data.name}</div>
                        </div>
                    </Link>
                    {/*<div className='child_categories'>*/}
                    {/*    {data.child_categories.length === 0 ? '' :*/}
                    {/*        <React.Fragment >*/}
                    {/*        <Link style={{textDecoration:"none"}} to={`/catalog/${data.child_categories[0]?.path}`}>*/}
                    {/*            <div className='subcategory' >{data.child_categories[0]?.name}</div>*/}
                    {/*        </Link>*/}
                    {/*        <Link style={{textDecoration:"none"}} to={`/catalog/${data.child_categories[1]?.path}`}>*/}
                    {/*            <div className='subcategory' >{data.child_categories[1]?.name}</div>*/}
                    {/*        </Link>*/}
                    {/*        </React.Fragment>*/}
                    {/*    }*/}
                    {/*</div>*/}
                </li>

            </ul>
        </>
    );
};

export default OneCategory;
