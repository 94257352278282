import React from 'react';
import styles from '../../styles/category.module.css'
import {toJS} from "mobx";
import {Link} from "react-router-dom";
const ModalLastChildCategory = ({data,active,setActive,index,reset}) => {


    return (
        <>

        <div   className={active === false ? styles.modal_children : styles.modal_children_active} >
            {data.child_categories?.map((item)=>{
                return(

                    <Link key={item.name} style={{textDecoration:'none'}} to={`/catalog/${item?.path}`}>
                    <div className={styles.children_item}>{item?.name}</div>
                    </Link>
                )
            })
            }
        </div>
        </>
    );
};

export default ModalLastChildCategory;