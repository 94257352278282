import React from 'react';
import styles from '../../styles/cartModal.module.css'
import img from '../../icon/product.png'
import {IoTrash} from 'react-icons/io5'
import itemStore from "../../Store/ItemStore";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import {Link} from "react-router-dom";
import style from "../../styles/browsedProducts.module.css";
import place from "../../icon/placeholder.jpg";
import trash from '../../icon/trash.png'

const CartModal = ({active,setActive,hideModal}) => {

    const {cart,totalPrice,totalAmount,remove,removeAll,addToBrowsed} = itemStore
    let indexInCart;
    cart.forEach((cartItem, index) => {
        if(cartItem.slug === cartItem.slug) {
            indexInCart = index;
        }
    })

    const removeItem = () => {
        remove(cart[indexInCart].slug)
    }
    let total=0
    cart.forEach((item) => {
        total += item.regular_price * item.amount
    })

    const removeCart =()=>{
        removeAll(cart)
    }


    return (


        <div  className={active ? `cartModal active` : `cartModal`} >
            <div onClick={e =>e.stopPropagation()} className='styleCartModal'  >
            <div className={styles.cart_title}>
         <div className={styles.cart_title_item}>Товары в корзине ({cart.length})</div>
          <div style={{cursor:'pointer'}} onClick={removeCart} className={styles.cart_title_remove}>Очистить корзину</div>
            </div>
            {cart.map((item)=>{
                return(
                    <div key={item.slug} className={styles.cart_item}>
                        <Link style={{textDecoration:'none',color: '#000000'}}  to={`/products/${item.categories.path}/${item.slug}`}>
                        {item.images.length === 0 ? <img  style={{cursor:'pointer'}} width={93} height={93} src={place} alt=""/> : <img style={{cursor:'pointer'}} width={93} height={93} src={item.images[0].url}  alt=''/>}
                            </Link>
                        <Link style={{textDecoration:'none',color: '#000000',maxWidth: '150px'}}  to={`/products/${item.categories.path}/${item.slug}`}>
                        <span style={{cursor:'pointer'}} className={styles.cart_desc}>{item.name}</span>
                        </Link>
                        <div className={styles.cart_price}>{`${item.regular_price} ₽`}</div>
                        <i style={{cursor:'pointer'}} onClick={removeItem}><img src={trash} alt=""/></i>
                    </div>
                )
            })}

            <hr style={{margin:'15px 0 7px 0'}}/>
            <div className={styles.cart_totalPrice}>
                <div>Итого:</div>
                <div>{`${total} ₽`}</div>
            </div>
            <div  className={styles.button_arr}>
                <Link to={'/cart'}>
                <button onClick={hideModal} style={{cursor:'pointer'}} className={styles.button_arrange}>Оформить заказ</button>
                </Link>
            </div>
            </div>

        </div>
    );
};

export default observer(CartModal);