import React, {useEffect} from 'react';
import img  from '../../icon/success.png'
import itemStore from "../../Store/ItemStore";

const PaymentSuccessful = () => {
    const {removeAll,cart} = itemStore
    useEffect(()=>{
        removeAll(cart)
    })
    return (
        <div className='error_container'>
            <img src={img} alt=""/>
            <h1 className='error'>Оплата заказа прошла успешно!</h1>
            <div style={{textAlign:'center'}} className='error_p'>В ближайшее время менеджер интернет-магазина
                свяжется с вами для уточнения деталей доставки.</div>
            <p className='error_p'>Спасибо за вашу покупку</p>
        </div>
    );
};

export default PaymentSuccessful;