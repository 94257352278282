import React from 'react';
import img from "../../icon/paymentError.png";

const PaymentError = () => {
    return (
        <div className='error_container'>
            <img src={img} alt=""/>
            <h1 className='error'>К сожалению, оплата заказа №905 не прошла успешно.</h1>
            <div style={{textAlign:'center',width:'580px'}} className='error_p'>Пожалуйста свяжитесь с нашей службой поддержки клиентов, чтобы мы могли
                помочь вам решить проблему.</div>

        </div>
    );
};

export default PaymentError;