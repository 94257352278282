import Header from "./components/Header/Header.jsx";
import Content from "./components/Content/Content";
import {Route, Routes, Navigate} from "react-router-dom";
import Cart from "./components/Cart/Cart";
import Favorites from "./components/Favourites/Favorites";
import Footer from "./components/Footer/Footer";
import About from "./components/blog/blogCardItem/blogCard/BlogCard";
import Item from "./components/Item/Item";
import Catalog from "./components/Category/Catalog";
import itemStore from "./Store/ItemStore";
import {useEffect} from "react";
import FilterSection from "./components/FilterSection/FilterSection";
import {observer} from "mobx-react-lite";
import Contacts from "./components/contacts/Contacts";
import Blog from "./components/blog/Blog";
import BlogCard from "./components/blog/blogCardItem/blogCard/BlogCard";
import Error from "./components/404/Error";
import PaymentSuccessful from "./components/paymentStatus/paymentSuccessful";
import PaymentError from "./components/paymentStatus/paymentError";


function App() {
    const {
        setFavoutesFromLS,
        setCartFromLs,
        setTotalCount,
        setTotalprice,
        setBrowsedFromLs,
        filterInputValue,
        setActiveModalSearch
    } = itemStore;

    useEffect(() => {
        setFavoutesFromLS();
        setCartFromLs();

        setBrowsedFromLs()
    }, [])
    useEffect(()=>{
        document.body.addEventListener('click', () => {
            setActiveModalSearch(false)
        })
    },[])
    return (
        <div className="App">
            <Header/>
            <Routes>
                {/*<Route  path='/' element={*/}
                {/*    filterInputValue.length > 0 ? <Navigate to={'/catalog'}/> : <Content/>*/}
                {/*}/>*/}
                <Route path='/' element={<Content/>}/>
                <Route path='/successful-payment' element={<PaymentSuccessful/>}/>
                <Route path='/failed-payment' element={<PaymentError/>}/>
                <Route path='/search' element={<Catalog/>}/>
                <Route path='/cart' element={<Cart/>}/>
                <Route path='/favourites' element={<Favorites/>}/>
                <Route path='/posts' element={<Blog/>}/>
                <Route path='/posts/:slug' element={<BlogCard/>}/>
                <Route path='/contacts' element={<Contacts/>}/>
                <Route path='/catalog' element={<Catalog/>}/>
                <Route path='/catalog/*' element={<Catalog/>}/>
                {/*<Route path='/catalog/:path/:child_categories' element={<Catalog/>}/>*/}
                <Route path='/products/:categories/:slug' element={<Item/>}/>
                <Route path='*' element={<Error/>} />


            </Routes>
            <Footer/>
        </div>
    );
}

export default observer(App);

// <Route path='/cart' element={<Cart/>}/>
// <Route path='/favourites' element={<Favorites/>}/>
// <Route path='/about' element={<blog/>}/>
// <Route path='/catalog' element={<Catalog/>}/>
// <Route path='/catalog/:slug' element={<Catalog/>}/>
// <Route path='/catalog/:slug/:child' element={<Catalog/>}/>
// <Route path='/products/:categories/:slug' element={<Item/>}/>
