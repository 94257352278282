import React, {useEffect} from 'react';
import {toJS} from "mobx";
import parse from "html-react-parser";
import style from '../../../styles/about.module.css'
import {Link} from "react-router-dom";
import img from '../../../icon/placeholder.jpg'

const BlogCardItem = ({data}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={style.card_item}>
            <Link style={{textDecoration: 'none'}} to={`/posts/${data.slug}`}>
                {data.images.length === 0 ? <img className={style.card_img} src={img}/> : <img className={style.card_img} src={data?.images[0].url} alt=""/>}
                <div className={style.card_wrap}>
                    <h1 className={style.card_title}>{data.title}</h1>
                    <div className={style.card_content}>{parse(data.content)}</div>

                </div>
            </Link>
        </div>
    );
};

export default BlogCardItem;