import React from 'react';
import img from '../../icon/ErrorFilters.png'

const ErrorFilters = () => {
    return (
        <div className='error_container'>
            <img className='error_img' src={img} alt=""/>
            <h1 className='error'>Товары с такими фильтрами не найдены.</h1>
            <div className='error_p'>Попробуйте изменить параметры фильтрации.</div>
            
        </div>
    );
};

export default ErrorFilters;