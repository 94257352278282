import React, {useEffect, useState} from 'react';
import style from '../../styles/browsedProducts.module.css'
import {observer} from "mobx-react-lite";
import itemStore from "../../Store/ItemStore";

import BrowsedProductsItem from "./BrowsedProductsItem";
import {toJS} from "mobx";
import Loader from "../Loader/Loader";

const BrowsedProducts = () => {

    const {BrowsedData,getDataBrowsed}=itemStore
    // const categoryArr =[{name:"Лампы",category:"lamps"},{name: "Провода",category: "wire"},{name: 'Распред.Коробки',category: "switchBox"},{name:"Светильники",category: "illuminator"}]
    // const [activeIndex, setActiveIndex] = useState(0)

    // const onClickBtn =(index)=>{
    //     setActiveIndex(index);
    //     const category = categoryArr[index].category;
    //     getDataBrowsed(category)
    // }
    const [isLoading,setIsLoading] = useState(false)


    useEffect(() => {
       setTimeout(()=>{
           setIsLoading(true)
       },1000)
    }, [])

    return (
        <>
        {
            BrowsedData.length===0 ?
            <div></div>
            :
                <div className={style.BrowsedProducts}>
                    <h1 className={style.BrowsedProducts_title}> Просмотренные</h1>
                    <div className={style.BrowsedProducts_container}>
                        <div className={style.BrowsedProducts_category}>
                            {/*{categoryArr.map((item, index) => {*/}
                            {/*    return (<button  key={index} onClick={()=>onClickBtn(index)}*/}
                            {/*        className={style.BrowsedProducts_btn && (activeIndex === index) ? style['active'] : '' }>{item.name}</button>)*/}
                            {/*})}*/}
                        </div >
                        <div className={style.BrowsedProducts_container_item} >
                            {!isLoading ? [...new Array(4)].map((_,index)=><Loader key={index}/>) : BrowsedData.map((item,index)=>{
                                    if(index < 4 ){
                                        return(
                                            <BrowsedProductsItem key={index} data={item}/>
                                        )
                                    }



                            })}
                        </div>

                    </div>
                </div>

        }
        </>

    );
};

export default observer(BrowsedProducts);