import React, {useEffect} from 'react';
import Category from "./Category";
import Block from "./Block";
import NewProducts from "./NewProducts";
import DiscountedProducts from "./DiscountedProducts";
import SalesLeader from "./SalesLeader";
import BrowsedProducts from "./BrowsedProducts";
import {observer} from "mobx-react-lite";
import itemStore from "../../Store/ItemStore";
import FilterSection from "../FilterSection/FilterSection";
import Catalog from "../Category/Catalog";


const Content = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        document.title = ' Электромир — магазин электро- и светотехнической продукции';
    }, []);
    const {filterInputValue}=itemStore
    return (
        <div className='container'>


           <div className='category_container'>
        <Category/>
        <Block/>
           </div>

        <NewProducts/>
        <DiscountedProducts/>
        <SalesLeader/>
        <BrowsedProducts/>




        </div>


    );
};

export default observer(Content);