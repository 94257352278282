import React, {useEffect, useState} from 'react';
import { IoLocationSharp } from 'react-icons/io5';
import {HiPhone} from 'react-icons/hi'
import {BiSearchAlt2} from 'react-icons/bi';
import {RiHeartsFill} from  'react-icons/ri'
import icon1 from '../../icon/cart.png'
import CartModal from "./CartModal";
import {observer} from "mobx-react-lite";
import itemStore from "../../Store/ItemStore";
import {Link, useNavigate} from "react-router-dom";
import style from "../../styles/browsedProducts.module.css";
import add_to_wishlist from "../../icon/add_to_wishlist.png";
import BurgerMenu from "../Content/burgerMenu";
import ModalSearchSuggestions from "../searchSuggestions/ModalSearchSuggestions";
import img from '../../icon/search.png'
import logo from '../../icon/1437ad.svg'
const Header = () => {

    const [active,setActive] =useState(false)
    const [activeInput,setActiveInput] = useState(false)
    const [search,setSearch] = useState( false)
    const [inWidth,setInWidth] = useState(false)
    const innerWidth = window.innerWidth;
    const {totalPrice,totalAmount,resetCatalog,filterInputValue,filterValue,totalAmountFavorites,Favorites,cart,getDataSearch,getDataFilters,setActiveModalSearch,setActiveModalCart,getDataPagination,resetFilters} = itemStore
    const navigate = useNavigate()




    const showModal=()=>{
        setActive(true)

     }
    const hideModal=()=>{
        setActive(false)

    }
     // const ShowModalCart = (e) =>{
     //    e.stopPropagation()
     //     setActiveModalCart(true)
     // }

     const showModalInput = (e) =>{
        e.stopPropagation()
      setActiveModalSearch(true)
     }

        const inputSubmit = (e)=>{
        if(e.key === 'Enter'){
            resetCatalog()
            resetFilters()
            getDataFilters('',filterInputValue)
              getDataPagination(1,'','','',filterInputValue);
            navigate(`/catalog?search_query=${filterInputValue}`)
            filterValue('')
        }


        }

     const inputSearch = (value)=>{
        filterValue(value)
    }



    const onclickSearch = () =>{
        resetCatalog()
        resetFilters()
        getDataFilters('',filterInputValue)
        getDataPagination(1,'','','',filterInputValue);
        filterValue('')

    }
    let total=0
    cart.forEach((item) => {
        total += item.regular_price * item.amount
    })
    const [showInput,setShowInput] = useState(false)
    const hideInput =() =>{
        setShowInput(!showInput)
    }
    return (
        <div  className='header_container'>
          <div className='header_top'>
              <nav className='header_link'>
                  <Link to={'/contacts'}>
                  <nav className='header_link_item_icon_contacts'>
                  <a href=""><IoLocationSharp style={{width:'16px',height:'16px'}}/></a>
                  <a href=""> г. Якутск, ул. Ойунского, 11, 1 этаж.</a>
                  </nav>
                  </Link>
                  <nav className='header_link_item'>
                  <Link to={'/catalog'}>Каталог товаров</Link>
                      <Link style={{textDecoration:"none"}} to={'/posts/uslovia-dostavki'}>Условия доставки</Link>
                  <Link to={'/posts'}>Блог</Link>
                      <Link to="/contacts">Контакты</Link>
                  </nav>
                  <nav className='header_link_item_icon'>
                  <a href=""><HiPhone style={{width:'16px',height:'16px'}}/></a>
                  <a className='contacts' style={{fontWeight:'600'}} href="tel:+79143053987"> +7-914-305-39-87 <span style={{fontWeight:'400'}}></span></a>
                  </nav>
              </nav>
          </div>
            <div className='header_bottom'>
                <div className='header_bottom_item'>

                   <BurgerMenu/>

                        <Link onClick={() => filterValue('')} style={{textDecoration:'none'}} to={'/'}>
                        <div className='logo'><img className='logotip' src={logo} alt=""/></div>
                        </Link>

                        <div className='header_input'>

                            <input onClick={showModalInput} onKeyDown={inputSubmit}
                                   onChange={(e) => inputSearch(e.target.value)} className='header_bottom_input'
                                   type="text" placeholder='Поиск по сайту' value={filterInputValue}/>
                            <Link style={{textDecoration:'none'}} onClick={onclickSearch} to={`/catalog`}> <i className='input_icon'><BiSearchAlt2
                                style={{width: '25px', height: '25px'}}/></i></Link>

                            {filterInputValue.length > 0 ?
                                <div><ModalSearchSuggestions active={activeInput} setActive={setActiveInput}
                                                             filter={filterInputValue}/></div> : ''}

                        </div>
                      <div className='search_adaptive'>


                        <div onClick={hideInput} className={!showInput ? 'header_input_adaptive_hide' : 'header_input_adaptive' }>
                        <div style={{width:'98%',position:'relative',margin:'0 auto',top:'6%'}}>
                        <input onClick={showModalInput} onKeyDown={inputSubmit}
                        onChange={(e) => inputSearch(e.target.value)} className='header_bottom_input_adaptive'
                        type="text" placeholder='Поиск по сайту' value={filterInputValue}/>
                        <Link style={{textDecoration:"none",position:"absolute",zIndex:'5',top:'8px'}} onClick={onclickSearch} to={`/catalog`}> <i className='input_icon_adaptive'><BiSearchAlt2
                        style={{width: '25px', height: '25px'}}/></i></Link>
                            {filterInputValue.length > 0 ?
                                <div><ModalSearchSuggestions active={activeInput} setActive={setActiveInput}
                                                             filter={filterInputValue}/></div> : ''}

                        </div>

                        </div>
                        </div>

                    <div style={{display:'flex',gap:'15px'}}>
                        <i className='search_icon' onClick={hideInput}><BiSearchAlt2
                            style={{width: '24px', height: '24px'}}/></i>
                    <Link style={{textDecoration:'none'}} to={'/favourites'}>
                    <div className='header_favourites'>
                        <div  style={{cursor:'pointer'}} className='header_favourites_amount'>{Favorites.length}</div>
                        <i style={{cursor:'pointer'}} className='input_icon_heart'><img className='add_to_favorites'  src={add_to_wishlist} alt=""/></i>
                        <div style={{cursor:'pointer'}} className='header_favourites_text'>Избранное</div>
                    </div>
                        </Link>


                            <div className='header_cart_adaptive'>

                            <Link className='link_cart'  to={'/cart'} style={{textDecoration:'none'}}>
                            <div onClick={showModal} className='header_cart_amount'>
                            {cart.length}
                        </div>
                            <img   style={{cursor:'pointer'}} onClick={showModal} className='cart_icon' src={icon1}/>
                            <CartModal active={active} setActive={setActive}/>
                            <div style={{cursor:'pointer'}} onClick={showModal} className='total_price'>
                                {`${total} ₽`}
                            </div>
                        </Link>
                            </div>

                            <div className='header_cart'>
                            <div onClick={showModal} className='header_cart_amount'>
                                {cart.length}
                            </div>
                            <img  style={{cursor:'pointer'}} onClick={showModal} className='cart_icon' src={icon1}/>

                            <div style={{cursor:'pointer'}} onClick={showModal} className='total_price'>
                        {`${total} ₽`}
                            </div>
                            </div>
                        <div className={active ? `cart_window` :` cart_none`} onClick={hideModal} >
                        <CartModal hideModal={hideModal} active={active} setActive={setActive}/>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    );
};

export default observer(Header);