import React, {useEffect, useState} from 'react';
import style from '../../../../styles/about.module.css'
import img  from '../../../../icon/about.png'
import axios from "axios";
import {useParams} from "react-router-dom";
import parse from "html-react-parser";



const BlogCard = () => {
const {slug} =useParams()
const [post,setPost]=useState({})

  useEffect (()=>{
      axios.get(`${process.env.REACT_APP_API_URL}/api/posts/${slug}`).then(({data})=>{
        setPost(data.data)

  })
},[])
console.log(post)
    return (
        <div className={style.about}>
            <h1 className={style.about_h1}>{post.title}</h1>
            <div className={style.about_container}>

                <div className={style.about_content}>
                    {Object.keys(post).length ? parse(post.content): ''}

                </div>
            </div>
        </div>
    );
};

export default BlogCard;