import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useParams} from "react-router";
import style from '../../styles/Item.module.css'
import img  from '../../icon/slide_4.jpg'
import img1  from '../../icon/slide_1.jpg'
import img2  from '../../icon/slide_2.jpg'
import img3 from '../../icon/wishlist-item.png'
import place from '../../icon/placeholder.jpg'
import add_to_wishlist from "../../icon/add_to_wishlist.png";
import BrowsedProducts from "../Content/BrowsedProducts";
import itemStore from "../../Store/ItemStore";
import {Link} from "react-router-dom";
import Att from "./att";
import Error from "../404/Error";
import add_to_wishlist_active from "../../icon/add_to_wishlist_active.png";
import {observer} from "mobx-react-lite";

const Item = () => {
    const {slug} = useParams();
    const {addToCart,addToFavorites,Favorites,removeToFavorites} = itemStore
    const [goodsData, setGoodsData] = useState([]);
    const arr=[ <img  src={goodsData.images} alt=""/>,<img  src={img} alt=""/>,<img src={img1} alt=""/>,<img src={img2} alt=""/>]

   const [active,setActive] = useState(0)
    const addItem = () => {

        addToCart(goodsData);
    }
    let indexFavorites;
    Favorites.forEach((item,index)=>{
        if(item.slug === goodsData.slug){
            indexFavorites = index
        }
    })
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const removeFavorites = () =>{
        removeToFavorites(Favorites[indexFavorites].slug)
    }
    const addFavorites= () => {

        addToFavorites(goodsData);
    }

      const onclickSlide=(index)=>{

          setActive(index)
      }
const [flag,setFlag]=useState(false)
    const includesFavorites = !!Favorites.filter(item => item.slug === goodsData.slug).length
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/products/${slug}`).then(({data}) => {
            setGoodsData(data.data);
        })
            .catch((e)=>{
                if(e.message.length > 0){
                    setFlag(!flag)
                }
            })


    }, [slug])

    useEffect(() => {
        document.title ='Электромир — ' + goodsData.name;
    }, [goodsData.name]);
console.log(goodsData)
    return (
        <>
            {flag === false ?
        <div className={style.item}>
           <div className={style.item_container}>
               <div className={style.item_img}>
                   <div className={style.item_arr}>
                       {goodsData.images?.length > 1 ?
                           goodsData.images?.map((item,index)=>{
                           return (

                               <div onClick={()=>onclickSlide(index)} key={index} className={ style.arr }>{<img  src={item.url} alt=""/>}</div>


                           )
                       })
                 : ''  }
                       </div>
                   {  goodsData.images?.length === 0 ?
                       <div className={ style.slide_active } >
                           <img src={place} alt=""/> </div> :
                     goodsData.images?.map((item,index)=>{
                          return <div className={ active === index ? style.slide_active : style.slide}  key={index}>
                              <img src={item.url} alt=""/> </div>
                       })
                   }
                   {/*<img style={{width: '459px',height: '444px'}} src={goodsData.img} alt=""/>*/}
               </div>
               <div className={style.item_about}>
               <div className={style.item_content}>
                   <h1 className={style.item_name}>{goodsData.name}</h1>
                   {!goodsData.description ? "" :  <div className={style.item_description}>{goodsData.description.length > 124 ? <>{ goodsData.description.slice(0,124)} <a href='#desc' className={style.short_description}>...</a></> : goodsData.description}</div> }
                   <div className={style.item_price}>{goodsData.regular_price === goodsData.final_price ?`${goodsData.regular_price} ₽` : <> <span style={{fontFamily: 'PT Sans',fontStyle:' normal',fontWeight: '700',fontSize:' 18px',lineHeight:' 23px',color: '#1437ad'}}>{goodsData.final_price} ₽</span> / <span style={{textDecoration:'line-through', fontWeight:'500',fontSize:'14px'}}>{goodsData.regular_price} ₽</span></>  }</div>

               </div>
               <div className={style.item_add}>
                   <Link style={{textDecoration:'none'}} to={'/cart'} >
               <button style={{cursor:"pointer"}} onClick={addItem} className={style.item_btn}><img src={img3} alt=""/>Оформить заказ</button>
                   </Link>
                   {  !includesFavorites  ? <img  style={{cursor:"pointer"}} className={style.add_to_favorites} onClick={addFavorites} src={add_to_wishlist} alt=""/>
                       :
                       <img  style={{cursor:"pointer"}} className={style.add_to_favorites} onClick={removeFavorites} src={add_to_wishlist_active} alt=""/>
                   }
                   {/*<img width={24} height={24} onClick={addFavorites} src={add_to_wishlist} alt=""/>*/}
               </div>
               </div>
           </div>
            {goodsData.attributes?.length === 0 ? '' :
                <div className={style.attribute}>
                    <h1 className={style.attribute_name}>Характеристики </h1>
                    <div className={style.attribute_container}>
                        {goodsData.attributes?.map((item)=>{
                            return <Att data={item} />
                        })}
                    </div>
                </div>}
            {!goodsData?.description ? ""
                :
                <div className={style.item_desc}>

                    <h1 className={style.item_name}>Описание </h1>
                    <div className={style.item_text}>
                        {goodsData?.description}
                    </div>
                </div>
            }

            <BrowsedProducts/>
        </div>
           : <Error/> }
           </>
    );
};

export default observer(Item);