import React, {useEffect, useRef, useState} from 'react';
import itemStore from "../../Store/ItemStore";
import {toJS} from "mobx";
import axios from "axios";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";

const ModalSearchSuggestions = ({active,filter,setActive}) => {
    const {resetFilterValue ,activeModalSearch} = itemStore
     const [ data,setData] = useState([])

        useEffect(()=>{
            axios.get(`${process.env.REACT_APP_API_URL}/api/products/?search_query=${filter}`).then(({data})=>{
                    setData(data.data)
            })
        },[filter])

const reset =()=>{
        resetFilterValue()
}



    return (
        <div  className={activeModalSearch ? 'search_modal_active' : 'search_modal'}>
            { data?.map((item,index)=>{
                return (
                    <Link  style={{textDecoration:'none'}} to={`/products/${item.categories?.path}/${item?.slug}`}>

                        <div className='search_text' onClick={reset}>{item.name}</div>
                    </Link>
                )
            })}
        </div>
    );
};

export default observer(ModalSearchSuggestions);