import React, {useEffect, useState} from 'react';
import style from "../../styles/cartStyle.module.css";
import itemStore from "../../Store/ItemStore";
import {toJS} from "mobx";
import place from "../../icon/placeholder.jpg";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";

import increm from '../../icon/PoligonLeft.png'
import decr from '../../icon/poligon.png'
import img from "../../icon/product.png";
import trash from "../../icon/trash.png";
import add_to_wishlist from "../../icon/add_to_wishlist.png";
import add_to_wishlist_active from "../../icon/add_to_wishlist_active.png";

const CartItem = ({data}) => {
    const {Favorites,remove,addToFavorites,cart,addToBrowsed,addToAmount,addToCart,removeCounter,removeToFavorites} = itemStore

    const addFavorites= () => {

        addToFavorites(data);
    }
    const pushToBrowsed =()=>{
        addToBrowsed(data)
    }

    let indexInCart;
    cart.forEach((cartItem, index) => {
        if(cartItem.slug === data.slug) {
            indexInCart = index;
        }
    })
    const removeItem = () => {
        remove(cart[indexInCart].slug)
    }


    const  inputChange =(value) =>{
        if(+value > +cart[indexInCart].stock_count){
            value = cart[indexInCart].stock_count
        }


        if(+cart[indexInCart].stock_count >= +value){
            addToAmount(data,+value)
        }
        // if(+value === 0){
        //     remove(cart[indexinCart].slug)
        // }

    }
    const incr =()=>{
        removeCounter(cart[indexInCart].slug)
    }
    const addItem = () => {

        addToCart(data);


    }
    const blur = () =>{
        if(+cart[indexInCart].amount === 0){
            addToAmount(data,cart[indexInCart].amount = 1)
        }
    }

    const includesFavorites = !!Favorites.filter(item => item.slug === data.slug).length
    let indexFavorites;
    Favorites.forEach((item,index)=>{
        if(item.slug === data.slug){
            indexFavorites = index
        }
    })
    const removeFavorites = () =>{
        removeToFavorites(Favorites[indexFavorites].slug)
    }


    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    return (
        <div className={style.cart_list_item}>
            <div className={style.cart_list_content}>
                {/*<img  src={data.img} alt=""/>*/}
                <Link style={{textDecoration:'none',color: '#000000'}} onClick={pushToBrowsed} to={`/products/${data.categories.path}/${data.slug}`}>
                {data.images.length === 0 ? <img style={{cursor:'pointer'}} width={93} height={93} src={place} alt=""/> : <img style={{cursor:'pointer'}} width={93} height={93} src={data.images[0]?.url} alt=''/>}
                </Link>
                    <div className={style.cart_text}>
                        <Link style={{textDecoration:'none',color: '#000000'}} onClick={pushToBrowsed} to={`/products/${data.categories.path}/${data.slug}`}>
                    <div style={{cursor:'pointer'}}>{data.name}</div>
                        </Link>
                    <div className={style.cart_text_button} >
                        {  !includesFavorites  ? <img  style={{cursor:"pointer"}} className={style.add_to_favorites} onClick={addFavorites} src={add_to_wishlist} alt=""/>
                            :
                            <img  style={{cursor:"pointer"}} className={style.add_to_favorites} onClick={removeFavorites} src={add_to_wishlist_active} alt=""/>
                        }

                        <i style={{cursor:'pointer'}} onClick={removeItem}><img src={trash} alt=""/></i>
                    </div>
                </div>
            </div>
            <div className={style.cart_list_amount}>
                <div style={{position:'relative'}}>
                <img src={increm} className={style.decr}  style={{cursor:"pointer"}} onClick={incr} ></img>
                {/*<span>{cart[indexinCart]?.amount}</span>*/}
                <input className={style.input_counter} min={1} onBlur={blur} onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}   onChange={(e)=>inputChange(e.target.value.replace(/\./g, '') )}  placeholder='1' value={data.amount || '' } type="number"/>
                <img src={decr} className={style.incr}  style={{cursor:"pointer"}} onClick={addItem} ></img>
                </div>
                {/*{ data.stock_status === 1 ?  <input onBlur={blur} onChange={(e)=>inputChange(e.target.value)} type={"number"} max={99} min={0} className={style.input} value={ data.amount || ''} ></input> : <span>Нет в наличии</span>}*/}
                {data.regular_price === data.final_price ? <div className={style.cart_list_price}>{`${data.regular_price * data.amount} ₽`}</div> : <div className={style.cart_list_price}>{`${data.final_price * data.amount} ₽`}</div> }

            </div>
        </div>
    );
};

export default observer(CartItem);