import React, {useEffect} from 'react';
import itemStore from "../../Store/ItemStore";
import {toJS} from "mobx";
import BlogCardItem from "./blogCardItem/BlogCardItem";
import {observer} from "mobx-react-lite";
import style from '../../styles/about.module.css'

const Blog = () => {
    const {getDataPosts, postsData} = itemStore;

    useEffect(() => {
        getDataPosts()
        document.title = ' Электромир — Блог';
    }, [])

    return (
        <div className={style.container}>
            <h1 className={style.about_h1}>Последние записи</h1>
            <div className={style.container_item}>

                {postsData.map((item) => {
                    return <BlogCardItem key={item.title} data={item}/>
                })}
            </div>
        </div>
    );
};

export default observer(Blog);