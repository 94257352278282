import React, {useState} from 'react';
import style from "../../../../styles/catalog.module.css";
import itemStore from "../../../../Store/ItemStore";
import {toJS} from "mobx";

const AttCheck = ({data,func,index,onClickCheckbox,activeLabel}) => {
    const {setAllFilter,getDataFilters} = itemStore;
    const [isShowSend ,setIsShowSend] = useState( false)

    const onClickData = (index)=> {
        onClickCheckbox(index,data)
        setIsShowSend(!isShowSend);


    }
    const onClickBtn =()=>{
        func()

    }



    return (
        <div style={{position:"relative"}}>
            {data === activeLabel ? <button style={{cursor:"pointer"}} onClick={onClickBtn} className={  style.button_send_active  }>Показать</button> : '' }
            <label style={{cursor:"pointer"}}><input  onClick={()=>onClickData(index)} className={style.catalog_checkbox}   type="checkbox"/><span style={{cursor:"pointer"}}
                className={style.real_checkbox}></span>{data}</label>
        </div>
    );
};

export default AttCheck;