import React, {useState} from 'react';
import styles from '../../styles/category.module.css'
import {toJS} from "mobx";
import {Link} from "react-router-dom";
import style from "../../styles/category.module.css";
import BurgerLastChild from "./burgerLastChild";
const BurgerChildSubCategory = ({data,close}) => {
    const [active,setActive] = useState(false)
    const onclickArrow = () =>{
        setActive(!active)
    }


    return (
        <>


        <div className={style.burger_section_child}>
            {data.child_categories.length !== 0 ?  <li onClick={onclickArrow}  className={active ? style.burger_li_active : style.burger_li} ></li> : ''}
            <Link onClick={close} style={{textDecoration:"none",color: '#4E4E4E'}} to={`/catalog/${data.path}`}>
            <div className={data.child_categories.length === 0 ? style.padding_name : ''}  style={{cursor:'pointer'}}> {data.name}</div>
            </Link>
        </div>
        <div  className={active ? style.burger_param_active_sub_last : style.burger_param} >
            {data.child_categories?.map((item,index)=>{
                return    <BurgerLastChild key={item.name } data={item} />
            })}



        </div>


        {/*{data?.child_categories?.map((item)=>{*/}
            {/*    return(*/}
            {/*        <>*/}
            {/*            */}

            {/*        </>*/}
            {/*    )*/}
            {/*})*/}
            {/*}*/}
        {/*</div>*/}
        </>
    );
};

export default BurgerChildSubCategory;