import axios from "axios";
import {makeAutoObservable, toJS, runInAction} from "mobx";
import cartItem from "../components/Cart/CartItem";

class ItemStore {

    DescData = [];
    NewData = [];
    NewDataPagination = [];
    LiderData = [];
    BrowsedData = [];
    cart = []
    totalPrice = 0;
    totalAmount = 0;
    totalAmountFavorites = 0;
    Favorites = []
    Catalog = []
    filterInputValue = ''
    paginaitedData = [];
    categories = []
    isLoadingDesc = false;
    isLoadingNew = false;
    isLoadingPopularity = false;
    isLoadingCatalog = false;
    isLoadingSearch = false;
    isLoadingPaginated = false
    SearchData = []
    filtersData = []
    postsData = []
    filterMinPrice =''
    filterMaxPrice =''
    allFilters = {};
    activeModalSearch = false;
    activeModalCart = false;
    filter = 'popularity'
    setActiveModalSearch = (bool) =>{
        this.activeModalSearch =bool
    }
    setActiveModalCart = (bool) =>{
        this.activeModalCart =bool
    }




    setAllFilter = (slug, value) => {
        if (!this.allFilters.hasOwnProperty(slug)) {
            this.allFilters[slug] = [value]
        } else {
            if (this.allFilters[slug].includes(value)) {
                let idx;
                this.allFilters[slug].forEach((item, index) => {
                    if(item === value) {
                        idx = index;
                    }
                })

                this.allFilters[slug].splice(idx, 1)

                if( this.allFilters[slug].length === 0) {
                    delete this.allFilters[slug];
                }
            } else {
                this.allFilters[slug] = [...this.allFilters[slug], value]
            }
        }


        console.log(toJS(this.allFilters))
    }

    filterValue = (value) => {
        this.filterInputValue = value;
    }
    resetFilterValue = () =>{
        this.filterInputValue = []
    }
    filterValueMinPrice =(value) =>{
        this.filterMinPrice = value
    }
    filterValueMaxPrice =(value) =>{
        this.filterMaxPrice = value
    }
    resetCatalog = () => {
        this.paginaitedData = [];
        console.log(toJS(this.paginaitedData))
    }
    resetFilters =() =>{
        this.allFilters ={}
    }
    // getDataProduct = (category) => {
    //     axios.get(`${process.env.REACT_APP_API_URL}/api/products/?category=${category}&order_by=popularity`)
    // }
    getDataCategories = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/categories`).then(({data}) => {
            runInAction(() => {
                this.categories = data
            })
        })
    }
    getDataNew = (category) => {
        this.isLoadingNew = true;
        axios.get(`${process.env.REACT_APP_API_URL}/api/products/?${category ? `category=${category}` : `category=elektrotehnika`}&order_by=data`).then(({data}) => {

            runInAction(() => {
                this.NewData = data.data

            })
        })
            .catch(() => console.log('rgre'))
            .finally(() => {
                this.isLoadingNew = false;
            })

    }

    getDataLider = (category) => {
        this.isLoadingPopularity = true;
        axios.get(`${process.env.REACT_APP_API_URL}/api/products/?${category ? `category=${category}` : `category=elektrotehnika`}&order_by=popularity&per_page=16`).then(({data}) => {
            runInAction(() => {
                this.LiderData = data.data

            })
        })
            .catch(() => console.log('rgre'))
            .finally(() => {
                this.isLoadingPopularity = false
            })
    }
    getDataDesc = (category) => {
        this.isLoadingDesc = true;
        axios.get(`${process.env.REACT_APP_API_URL}/api/products/?${category ? `category=${category}` : `category=elektrotehnika`}&order_by=price-desc`).then(({data}) => {
            runInAction(() => {

                this.DescData = data.data

            })
        })
            .catch(() => console.log('rgre'))
            .finally(() => {
                this.isLoadingDesc = false
            })

    }
    getDataCatalog = () => {
        this.isLoadingCatalog = true;
        axios.get(`${process.env.REACT_APP_API_URL}/api/products/`).then(({data}) => {
            runInAction(() => {
                this.Catalog = data.data

            })

        })
            .catch(() => console.log('rgre'))
            .finally(() => {
                this.isLoadingCatalog = false
            })
    }
    getDataSearch = (value) => {
        this.isLoadingSearch = true
        axios.get(`${process.env.REACT_APP_API_URL}/api/products/?search_query=${value}`).then(({data}) => {
            runInAction(() => {
                this.SearchData = data.data

            })

        })
            .catch(() => console.log('rgre'))
            .finally(() => {
                this.isLoadingSearch = false
            })
    }
 flag = false

    changeFilter = (value) => {
        this.filter = value;
    }
 lastPage=0
    getDataPagination = (page, path,min,max,search) => {
        let filters = '';
        Object.entries(this.allFilters).forEach((item, index) => {
            filters += `${item[0]}=`;
            item[1].forEach((name, index) => {
                if(index === item[1].length - 1) {
                    filters += `${name}`
                }else {
                    filters += `${name};`
                }
            })
            if(index !== Object.entries(this.allFilters).length - 1) {
                filters += '&'
            }

        })
        const baseUrl=`${process.env.REACT_APP_API_URL}/api/products`
        const queryParams = [
            page ? `page=${page}` : null
            ,path ? `category=${path}` : null,
            filters ? filters : null
            ,min ? `min_price=${min}` : null
            ,max ? `max_price=${max}` : null
            ,search ? `search_query=${search}` : null
        ].filter(Boolean)
        const url = queryParams.length > 0
            ? `${baseUrl}?${queryParams.join('&')}`
            : baseUrl
        this.isLoadingPaginated = true
            // `${process.env.REACT_APP_API_URL}/api/products/?${path ? `category=${path}` : ''}${child_categories ? `/${child_categories}` : ''}${filters ? `&${filters}` : ''}${min ? `&min_price=${min}` : ''}${max ? `&max_price=${max}` : ''}${page ? `&page=${page}` : ''}
        axios.get(`${url}&order_by=${ this.filter}&per_page=50`).then(({data}) => {
            runInAction(() => {

                this.lastPage = data.meta.last_page
                data.data.forEach((item) => {
                    this.paginaitedData.push(item)

                })

            })

        })

            .catch(()=>{
                if(this.paginaitedData.length === 0){
                    this.flag = true
                }
            })
            .finally(() => {
                this.isLoadingPaginated = false
                if(this.paginaitedData.length !== 0){
                    this.flag = false
                }
            })

    console.log(this.flag)
    }
    getDataFilters = (category,search,min,max) => {
        let filters = '';
        Object.entries(this.allFilters).forEach((item, index) => {
            filters += `${item[0]}=`;
            item[1].forEach((name, index) => {
                if(index === item[1].length - 1) {
                    filters += `${name}`
                }else {
                    filters += `${name};`
                }
            })
            if(index !== Object.entries(this.allFilters).length - 1) {
                filters += '&'
            }

        })
        const baseUrl=`${process.env.REACT_APP_API_URL}/api/filters`
        const queryParams = [
            category ? `category=${category}` : null,
            search ? `search_query=${search}` : null,
            filters ? filters : null
            ,min ? `min_price=${min}` : null
            ,max ? `max_price=${max}` : null
        ].filter(Boolean)
        const url = queryParams.length > 0
            ? `${baseUrl}?${queryParams.join('&')}`
            : baseUrl
        axios.get(`${url}`).then(({data}) => {
            runInAction(() => {
                this.filtersData = data
            })
        })
    }
    getDataPosts = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/posts/`).then(({data}) => {
            runInAction(() => {
                this.postsData = data.data
            })
        })
    }


    // addToCart = (item) => {
    //     let isInCart = false;
    //     this.cart.forEach((cartItem, index) => {
    //         if (cartItem.slug === item.slug) {
    //             cartItem.amount += 1;
    //             cartItem.totalPrice += cartItem.price;
    //             this.totalPrice += cartItem.price;
    //             this.totalAmount += 1;
    //             isInCart = true;
    //
    //         }
    //     })
    //
    //     if (!isInCart) {
    //         this.cart.push({
    //             slug: item.slug,
    //             name: item.name,
    //             price: item.price,
    //             amount: 1,
    //             img: item.img,
    //             totalPrice: item.price,
    //
    //         });
    //         this.totalAmount += 1
    //         this.totalPrice += item.price;
    //     }
    //     localStorage.setItem('cart', JSON.stringify(this.cart))
    //     localStorage.setItem('count', JSON.stringify(this.totalAmount))
    //     localStorage.setItem('price', JSON.stringify(this.totalPrice))
    // }
    addToCart = (item) => {
        let flag =false
        this.cart.forEach((CartItem,index)=>{
            if(CartItem.slug ===item.slug){
                flag = true
                if(+CartItem.stock_count > CartItem.amount)
                CartItem.amount += 1
            }
        })
        if(!flag){
            this.cart.push({...item,amount:1})
            // this.cart.map(item=>({...item,amount:1}))

        }


        localStorage.setItem('cart', JSON.stringify(this.cart))
    }
    removeCounter = (slug) => {
        this.cart.forEach((cartItem, index) => {
            if (cartItem.slug === slug) {
                cartItem.amount -= 1

            }
            if(cartItem.amount===0){
                this.cart.splice(index, 1);
            }

        })

        localStorage.setItem('cart', JSON.stringify(this.cart))

    }


     addToAmount =(itemCart,value)=>{
        this.cart.forEach((item,index)=>{
            if(itemCart.slug === item.slug){
                this.cart[index].amount = value

            }


        })
         localStorage.setItem('cart', JSON.stringify(this.cart))
     }

    addToBrowsed = (item) => {
        let flag = false
        this.BrowsedData.forEach((brow,index)=>{
            if(brow.slug === item.slug){
                flag = true
            }
        })
      if (!flag){
          this.BrowsedData.unshift(item)
      }





        localStorage.setItem('BrowsedData', JSON.stringify(this.BrowsedData))
    }


    remove = (slug) => {
        this.cart.forEach((cartItem, index) => {
            if (cartItem.slug === slug) {
                    this.cart.splice(index, 1);

            }
        })
        localStorage.setItem('cart', JSON.stringify(this.cart))

    }


    setFavoutesFromLS = () => {
        if (localStorage.getItem('favourites')) {
            this.Favorites = JSON.parse(localStorage.getItem('favourites'))
        }
    }
    setCartFromLs = () => {
        if (localStorage.getItem('cart')) {
            this.cart = JSON.parse(localStorage.getItem('cart'))
        }
    }
    setBrowsedFromLs = () => {
        if (localStorage.getItem('BrowsedData')) {
            this.BrowsedData = JSON.parse(localStorage.getItem('BrowsedData'))
        }
    }



    addToFavorites = (item) => {
        let flag = false
        this.Favorites.forEach((favorItem,index)=>{
            if(favorItem.slug === item.slug){
                flag= true
            }
        })
        if(!flag){
            this.Favorites.push(item)
        }

        // let isInCart = false;
        // this.Favorites.forEach((cartItem, index) => {
        //     if (cartItem.slug === item.slug) {
        //         this.totalAmountFavorites += 1;
        //         isInCart = true;
        //
        //     }
        // })
        //
        // if (!isInCart) {
        //
        //     this.Favorites.push({
        //         slug: item.slug,
        //         name: item.name,
        //         price: item.price,
        //         amount: 1,
        //         img: item.img,
        //         totalPrice: item.price,
        //
        //     });
        //     this.totalAmountFavorites += 1
        //
        // }
        // console.log(toJS(this.Favorites))
        localStorage.setItem('favourites', JSON.stringify(this.Favorites))

    }

    removeToFavorites = (slug) => {
        this.Favorites.forEach((cartItem, index) => {
            if (cartItem.slug === slug) {
            this.Favorites.splice(index, 1);
            }
            // if (cartItem.slug === slug) {
            //     cartItem.totalPrice -= cartItem.price;
            //
            //     cartItem.amount -= 1;
            //     this.totalAmountFavorites -= 1
            //     if (cartItem.amount === 0) {
            //         this.Favorites.splice(index, 1);
            //     }
            // }

        })

        localStorage.setItem('favourites', JSON.stringify(this.Favorites))
    }


    removeAll = (arr) => {
        arr.length = 0
        this.totalAmount = 0;
        this.totalPrice = 0
        localStorage.setItem('cart', JSON.stringify(this.cart))

    }

    constructor() {

        makeAutoObservable(this)
    }
}

export default new ItemStore()