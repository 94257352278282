import React, {useEffect, useState} from 'react';
import style from '../../styles/liderSales.module.css'
import {observer} from "mobx-react-lite";
import itemStore from "../../Store/ItemStore";
import SalesLeaderItem from "./SalesLeaderItem";
import Loader from "../Loader/Loader";
import NewProductsItem from "./NewProductsItem";
import {toJS} from "mobx";
import ScrollContainer from "react-indiana-drag-scroll";
const LiderSales = () => {

    const {getDataLider,LiderData,categories,getDataCategories,isLoadingPopularity}=itemStore

    const [activeIndex, setActiveIndex] = useState(0)

    const onClickBtn =(index)=>{
        setActiveIndex(index);
        const category = categories[index].path;
       getDataLider(category)
    }

    useEffect(() => {
        getDataLider();
        getDataCategories()

    }, [])

    return (
        <div className={style.liderSales}>
            <h1 className={style.liderSales_title}>Популярные товары</h1>
            <div className={style.liderSales_container}>
                <div className={style.liderSales_category}>
                    <ScrollContainer style={{display:'flex',gap:'30px'}}>
                    {categories.map((item, index) => {
                        return (<button style={{cursor:"pointer"}} key={index} onClick={()=>onClickBtn(index)}
                            className={style.liderSales_btn && (activeIndex === index) ? style['active'] : '' }>{item.name}</button>)
                    })}
                    </ScrollContainer>
                </div >
                <div className={style.liderSales_container_item} >
                    {isLoadingPopularity ? [...new Array(16)].map((_,index)=><Loader key={index}/>) :
                        LiderData.map((item,index)=>{
                            if(index<16) {
                                return (
                                    <SalesLeaderItem key={index} data={item}/>
                                )
                            }
                        })}

                </div>

            </div>
        </div>
    );
};

export default observer(LiderSales);