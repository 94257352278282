import React, {useEffect, useRef, useState} from 'react';
import style from '../../styles/catalog.module.css'
import itemStore from "../../Store/ItemStore";
import {observer} from "mobx-react-lite";
import BrowsedProducts from "../Content/BrowsedProducts";
import AllItem from "./Alltem";
import Loader from "../Loader/Loader";
import FilterSection from "../FilterSection/FilterSection";
import {useParams} from "react-router-dom";
import FilterAttributes from "../FilterSection/Attribute/FilterAttributes";
import {runInAction, toJS} from "mobx";
import ErrorFilters from "../errorFilters/ErrorFilters";
import InfiniteScroll from "react-infinite-scroll-component";
import {IoMdCloseCircle} from "react-icons/io";
import img from '../../icon/Group 15 (2).png'
const Catalog = () => {

    const sortNamesArr = [{name: 'Сначала дешевле', selector:'price' }, {
        name: 'Сначала дороже',
        selector: 'price-desc'
    }];
    const {
        filterValueMinPrice,
        filterValueMaxPrice,
        filterMaxPrice,
        filterMinPrice,
        filterInputValue,
        resetCatalog,
        getDataSorted,
        getDataPagination,
        paginaitedData,
        isLoadingPaginated,
       getDataLider,
        getDataFilters,
        filtersData,
        setAllFilter,
        allFilters,
        SearchData,
        resetFilters,
        flag,
        changeFilter,
        lastPage

    } = itemStore;

    const { '*' : path} = useParams();
    const [activeSortValue, setActiveSortValue] = useState('')
    const [page, setPage] = useState(1);
    // const [fetching,setFetching] = useState(true)
    // const scrollRef = useRef(null)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [path]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [window.location.reload]);
    useEffect(() => {

        resetCatalog()
        resetFilters()
            setPage(1)
        if(filterInputValue.length ===0 ){
            getDataFilters(path,filterInputValue)
            getDataPagination(1,path,'','',filterInputValue)
        }

    },  [path])



    const OnClickBtn = async () => {
        setPage(page + 1)
        await getDataPagination(page + 1, path, '', '', filterInputValue)

    }
    const onChangeActiveSort = async (value) => {

        resetCatalog()
        setPage(1)
        setActiveSortValue(value)
        // const activeArr = sortNamesArr.filter(item => item.name === value)
        changeFilter(value)
        // if (activeArr.length) {

        await getDataPagination(1, path, filterMinPrice, filterMaxPrice, filterInputValue)
        //
        // }
    }

    const changeMaxPrice =(value) =>{
        filterValueMaxPrice(value)
    }
    const changeMinPrice =(value) =>{
        filterValueMinPrice(value)
    }

 const changeFilters = () =>{
     resetCatalog()
     getDataFilters(path,filterInputValue,filterMinPrice,filterMaxPrice)
     getDataPagination(1,path,filterMinPrice,filterMaxPrice)
     setShowFilters(false)
 }
 // const resetFilters = () =>{
 //       this.allFilters = {}
 //     setAllFilter('','')
 //     resetCatalog()
 //     getDataFilters(path)
 //     getDataPagination(1,path,child_categories)
 //
 // }
    const [showInput,setShowInput] = useState(false)
        const inerWidth=window.innerWidth
    const show =()=>{
        setShowFilters(!showFilters)
    }
    const close = () =>{
        setShowFilters(false)
    }
    const [showFilters,setShowFilters] = useState(false)


    return (

        <div id='1' className={style.catalog}>
            <div className={style.container_adaptive}>
            <button onClick={show} className={style.filter_btn}><img width={18} height={18} src={img} alt=""/>Фильтровать</button>
            {inerWidth <= 1271 ?  <div className={style.catalog_sort_item_adaptive}>
                <div className={style.catalog_sort_item_text}>Cортировка :</div>
                <select style={{cursor:"pointer"}}   value={activeSortValue} onChange={(e) => onChangeActiveSort(e.target.value) }
                        className={style.catalog_select}>
                    {sortNamesArr.map((item) => {
                        return <option key={item.name}  value={item.selector}><span  style={{cursor:"pointer"}} >{item.name}</span></option>
                    })}
                </select>

            </div>
           : '' }
            </div>
            <div className={ style.catalog_container}>

                    <div className={!showFilters ? style.catalog_input : style.catalog_input_hide}>

                    {/*<div className='header_input1'>*/}
                    {/*    <input onChange={(e) => filterValue(e.target.value)} className='header_bottom_input1'*/}
                    {/*           type="text" placeholder='Поиск по категории' value={filterInputValue}/>*/}
                    {/*    <i className='input_icon'><BiSearchAlt2 style={{width: '25px', height: '25px'}}/></i>*/}
                    {/*</div>*/}
                    <div className={style.catalog_filter}>

                        <IoMdCloseCircle onClick={close} className={style.icon} style={{width:'30px',height:'30px',position:'absolute',color:'#1437ad',top:'20px',right:'8%',zIndex:'4'}}/>
                        <ul className={style.catalog_filter_input}>

                            <div className={style.catalog_arrow_1}>
                                <li onClick={() => setShowInput(!showInput)}
                                    className={showInput ? style.catalog_li_active : style.catalog_li}></li>
                                <div style={{cursor:'pointer'}} onClick={() => setShowInput(!showInput)} >Фильтр по цене</div>
                                <div className={showInput ? style.catalog_sort_active : style.catalog_sort}>
                                    <input onChange={(e)=> changeMinPrice(e.target.value)} className={style.catalog_place} placeholder='От 1' type="text" value={filterMinPrice}/>
                                    <input onChange={(e)=> changeMaxPrice(e.target.value)} className={style.catalog_place} placeholder='До 7500' type="text" value={filterMaxPrice}/>
                                </div>
                            </div>
                        </ul>
                        <ul className={style.catalog_filter_input}>
                        <FilterAttributes func={changeFilters}  data={filtersData}/>

                        </ul>
                        <div style={{display:'flex',flexDirection:'column',gap:'16px'}}>
                        <button style={{cursor:"pointer"}} className={style.catalog_btn} onClick={changeFilters}>Применить</button>
                            <button style={{cursor:"pointer"}}  className={style.catalog_btn_res} onClick={()=>window.location.reload()}>Сбросить</button>
                        </div>
                    </div>
                        <div onClick={close} className={showFilters ? style.place : style.none}></div>

                </div>

                <div className={style.catalog_list}>

                    <div className={style.catalog_list_item}>
                        <div className={style.catalog_sort_item}>
                            <div className={style.catalog_sort_item_text}>Cортировка :</div>
                            <select style={{cursor:"pointer"}}   value={activeSortValue} onChange={(e) => onChangeActiveSort(e.target.value) }
                                    className={style.catalog_select}>
                                {sortNamesArr.map((item) => {
                                    return <option key={item.name} value={item.selector}><span   style={{cursor:"pointer"}} >{item.name}</span></option>
                                })}
                            </select>

                        </div>

                        <div  className={style.catalog_list_render}>
                            {flag === false ?
                                <>

                                    {isLoadingPaginated ? [...new Array(paginaitedData.length === 0 ?  50 : paginaitedData.length + 50)].map((_,index) => <Loader key={index}/>) :

                                        paginaitedData.map((item, index) => {
                                            return (

                                                <AllItem  path={path} index={index} key={item.slug} data={item}/>

                                            )

                                        })  }

                                </>
                           : <ErrorFilters/> }





                        </div>


                    </div>
                    <button disabled={page === lastPage }  style={{cursor:"pointer"}} onClick={OnClickBtn} className={style.button_all}>Показать еще</button>
                </div>

            </div>


            <BrowsedProducts/>

        </div>
    );
};

export default observer(Catalog);