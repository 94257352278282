import React, {useEffect} from 'react';
import style from '../../styles/davorites.module.css'
import itemStore from "../../Store/ItemStore";
import {observer} from "mobx-react-lite";
import FavoritesItem from "./FavoritesItem";
const Favorites = () => {
    const {Favorites} = itemStore

    useEffect(()=>{
        document.title = 'Электромир —  Избранное';
    },[])

    return (
       Favorites.length === 0 ? <h1 className={style.Favorites_null}>В Избранном пусто </h1>
            :
           <div className={style.Favorites}>
           <h1 className={style.Favorites_title}>Избранное</h1>
            <div  className={style.Favorites_container}>
                <div className={style.Favorites_list}>
                    {Favorites.map((item)=>{
                       return(
                          <FavoritesItem key={item.slug} data={item}/>
                       )
                    })}
                </div>


            </div>



        </div>
    );
};

export default observer(Favorites);