import React, {useState} from 'react';
import FilterNameAttribute from "./AttributeCheckbox/FilterNameAttribute";
import {observer} from "mobx-react-lite";
import {set} from "mobx";


const FilterAttributes = ({data,func}) => {
    const [activeLabel, setActiveLabel] = useState('');
    return (
        <>
        {/*<div className={style.catalog_arrow}>*/}
        {/*    <li onClick={() => setShowFilter(!showFilter)}*/}
        {/*        className={showFilter ? style.catalog_li_active : style.catalog_li}></li>*/}

        {/*    <div>Фильтр по Параметрам</div>*/}
        {/*</div>*/}

            {data.attributes?.map((item,index)=>{
                return(
                    <React.Fragment key={item.name }>
                        <FilterNameAttribute  func={func} activeLabel={activeLabel} setActiveLabel={setActiveLabel}  data={item}/>




                    </React.Fragment>)

            })}

        </>
    );
};

export default observer(FilterAttributes);