import React, {useState} from 'react';
import styles from '../../styles/category.module.css'
import {toJS} from "mobx";
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import itemStore from "../../Store/ItemStore";
import ModalChildCategory from "./ModalChildCategory";
import {defaultFallbackInView} from "react-intersection-observer";
import style from "../../styles/category.module.css";
import BurgerChildSubCategory from "./BurgerChildSubCategory";


const BurgerChildCategory = ( {data,close}) => {
const {categories} = itemStore

    const [active,setActive] = useState(false)
    const onclickArrow = () =>{
setActive(!active)
    }

    return (
       <div className={styles.burger_li_title}>



                   <div className={style.burger_section_child}>
                       {data.child_categories.length !== 0 ? <li onClick={onclickArrow}  className={active ? style.burger_li_active : style.burger_li} ></li> : ''}
                       <Link onClick={close} style={{textDecoration:"none",color: '#4E4E4E'}} to={`/catalog/${data.path}`}>
                       <div className={data.child_categories.length === 0 ? style.padding_name : ''} style={{cursor:'pointer' }}> {data.name}</div>
                       </Link>
                   </div>

                       <div  className={active ? style.burger_param_active_sub : style.burger_param} >

                            {data.child_categories?.map((item,index)=>{
                               return    <BurgerChildSubCategory key={item.name} close={close} data={item} />
                           })}



                   </div>




       </div>

    );
};

export default observer(BurgerChildCategory);
// <div className={active ? `categoryModal1 active` : `categoryModal1`}>
//     <div  onClick={retur}  className={styles.burger_arrow}>
//         <div onClick={retur} className={styles.burger_childs_arrow}>
//             <li
//                 className={ styles.catalog_li1}></li>
//
//         </div>
//         <button className='burger_btn' onClick={retur} >Вернуться в Категории </button>
//     </div>
//     {/*<Link style={{textDecoration:"none"}} to={`/catalog/${categories[id]?.path}`}>*/}
//     {/*<div className={styles.categoryModal_title}>{categories[id]?.name}</div>*/}
//     {/*    </Link>*/}
//
//     <div className={styles.subCategory}>
//         <div  className= 'category_title'  >{name.name}</div>
//         {data.map((item, index) => {
//             return (
//                 <div  key={item.name} onMouseOver={()=>show(index)}  className={styles.child}>
//
//                     {num === index  && item.child_categories.length !==0 ?   <ModalChildCategory reset={reset}  index={index}  active={activeChildsModal} setActive={setActiveChildModal}  data={item}/> : '' }
//
//
//                     <Link  className='ModalCategory_link_child' to={`/catalog/${item?.path}`}>
//                         <div style={{width: '350px'}}>
//                             <div    className={styles.catalog_arrow}>
//                                 <div className={styles.childs_arrow}>
//                                     {item.child_categories.length !== 0 ?   <li
//                                         className={index  === value ? styles.catalog_li_active :  styles.catalog_li}></li> : ''}
//
//                                 </div>
//                                 <div className={styles.child_span}  onMouseOver={()=>show(index)} >{item?.name}</div>
//
//                             </div>
//                         </div>
//                     </Link>
//                 </div>
//             )
//
//         })}
//
//
//     </div>
// </div>