import React from 'react';
import style from "../../styles/newProducts.module.css";
import {Link} from "react-router-dom";

import itemStore from "../../Store/ItemStore";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import add_to_cart from "../../icon/add_to_cart.png";
import add_to_cart_active from "../../icon/add_to_cart_active.png";
import add_to_wishlist from "../../icon/add_to_wishlist.png";
import wishlist_active from "../../icon/add_to_wishlist_active.png";
import place from "../../icon/placeholder.jpg";
import increm from '../../icon/PoligonLeft.png'
import decr from '../../icon/poligon.png'
const NewProductsItem = ({data}) => {
    const {addToCart,addToFavorites,removeToFavorites,Favorites ,cart,remove,addToBrowsed,BrowsedData,counter,removeCounter,addToAmount}=itemStore;

  const pushInBrowsed =( )=>{
          addToBrowsed(data);
      }

    let isInFavourite = !!Favorites.filter(item => item.slug === data.slug).length;
    let isInCart =!!cart.filter(item=>item.slug === data.slug).length;


    const addItem = () => {

        addToCart(data);


    }
    const addFavorites= () => {
        addToFavorites(data)

    }



    let indexInFavorites;
    Favorites.forEach((cartItem, index) => {
        if(cartItem.slug === data.slug) {
            indexInFavorites = index;
        }
    })
    let indexinCart;
    cart.forEach((item,index)=>{
        if(item.slug ===data.slug){
            indexinCart = index
        }
    })

    const removeIsCart = ()=>{
        remove(cart[indexinCart].slug)
    }
    const removeItem = () => {
        removeToFavorites(Favorites[indexInFavorites].slug)
    }
    const  inputChange =(value) =>{
        if(+value > +cart[indexinCart].stock_count){
            value = cart[indexinCart].stock_count
        }


        if(+cart[indexinCart].stock_count >= +value){
            addToAmount(data,+value)
        }
        // if(+value === 0){
        //     remove(cart[indexinCart].slug)
        // }

    }
    const blur = () =>{
        if(+cart[indexinCart].amount === 0){
           addToAmount(data,cart[indexinCart].amount = 1)
        }
    }

    const incr =()=>{
        removeCounter(cart[indexinCart].slug)
}
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    return (
        <div className={style.newProduct_item}>
            <div className={style.top}>
                <div className={style.discountedProducts_wrap}>
                    <Link onClick={pushInBrowsed} style={{textDecoration:`none`,width:'93px',height:'93px',cursor:"pointer"}} to={`/products/${data.categories.path}/${data.slug}`}>

                        {data.images.length === 0 ? <img className={style.newProduct_img} src={place} alt=""/> : <img className={style.newProduct_img} src={data.images[0]?.url} alt=''/>}
                    </Link>

                </div>
                <div className={style.newProduct_desc}>
                    <Link onClick={pushInBrowsed} className={style.newProduct_link} style={{textDecoration:`none`,cursor:"pointer"}} to={`/products/${data.categories.path}/${data.slug}`}>
                        <div className={style.newProduct_name}>{ data.name }</div>
                        <div className={style.newProduct_price}>{data.regular_price === data.final_price ?`${data.regular_price} ₽` : <> <span style={{fontFamily: 'PT Sans',fontStyle:' normal',fontWeight: '700',fontSize:' 18px',lineHeight:' 23px',color: '#1437ad'}}>{data.final_price} ₽</span> / <span style={{textDecoration:'line-through', fontWeight:'500',fontSize:'14px'}}>{data.regular_price} ₽</span></>  }</div>
                    </Link>
                </div>
            </div>
            <div className={ !isInCart ? style.discountedProducts_disc :  style.discountedProducts_disc_open}>

                <div className={ !isInCart ? style.discountedProducts_disc_container : style.discountedProducts_disc_container_open}>
                    {!isInCart ? <img  style={{cursor:"pointer"}} className={style.add_to_cart} onClick={addItem} src={add_to_cart} alt=""/>
                        :
                        <img  style={{cursor:"pointer"}} className={style.add_to_cart}  onClick={removeIsCart} src={add_to_cart_active} alt=""/>}
                    <div className={!isInCart ? style.counter_hide : style.counter}>
                        <img src={increm} className={style.decr}  style={{cursor:"pointer"}} onClick={incr} ></img>
                        {/*<span>{cart[indexinCart]?.amount}</span>*/}
                        <input className={style.input_counter} min={1} onBlur={blur} onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} onChange={(e)=>inputChange(e.target.value )}  placeholder='1' value={cart[indexinCart]?.amount || '' } type="number"/>
                        <img src={decr} className={style.incr}  style={{cursor:"pointer"}} onClick={addItem} ></img>
                    </div>
                    {!isInFavourite ? <img  style={{cursor:"pointer"}} className={style.add_to_favorites} onClick={addFavorites} src={add_to_wishlist} alt=""/>
                        :
                        <img style={{cursor:"pointer"}} className={style.add_to_favorites} onClick={removeItem}  src={wishlist_active} alt=""/>
                    }
                </div>

            </div>
        </div>

    );
};

export default observer(NewProductsItem);