import React from 'react';
import style from '../../styles/Item.module.css'
const Att = ({data}) => {
    return (
        <div className={style.att_container}>
            <div className={style.att_name}> {data.name}</div>
            <div className={style.att_value}>{data.value}</div>
        </div>
    );
};

export default Att;