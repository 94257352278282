import React, {useEffect, useRef, useState} from 'react';
import style from "../../../../styles/catalog.module.css";
import {toJS} from "mobx";
import itemStore from "../../../../Store/ItemStore";
import {observer} from "mobx-react-lite";
import AttCheck from "./AttCheck";



const FilterNameAttribute = ({data,func,activeLabel,setActiveLabel}) => {

    const {setAllFilter,getDataFilters} = itemStore;

    const [showChildrenFilter,setShowChildrenFilter] = useState(false);




   const onClickCheckbox = (index, labelData) => {
       setAllFilter(data.slug, data.values[index])
       if(labelData !== activeLabel) {
           setActiveLabel(labelData)
       }

   }






    return (
        <>

        <div className={style.catalog_arrow}>
            <li onClick={() => setShowChildrenFilter(!showChildrenFilter)}
                className={showChildrenFilter ? style.catalog_li_active : style.catalog_li}></li>
            <div style={{cursor:'pointer'}} onClick={() => setShowChildrenFilter(!showChildrenFilter)}>{data?.name}</div>
        </div>
            <div className={showChildrenFilter ? style.catalog_param_active : style.catalog_param}>

                {data.values?.map((item, index)=>{
                    return  ( <AttCheck key={item.name } activeLabel={activeLabel}  onClickCheckbox={onClickCheckbox} func={func} index={index} data={item}/>)
                })}

            </div>
        </>

    );
};

export default observer(FilterNameAttribute);