import React from 'react';
import style from "../../styles/davorites.module.css";
import itemStore from "../../Store/ItemStore";
import place from "../../icon/placeholder.jpg";
import {toJS} from "mobx";
import {Link} from "react-router-dom";
import add_to_cart from "../../icon/add_to_cart.png";
import add_to_cart_active from "../../icon/add_to_cart_active.png";
import img from "../../icon/product.png";
import trash from "../../icon/trash.png";
import {observer} from "mobx-react-lite";

const FavoritesItem = ({data}) => {

    const {Favorites,removeToFavorites,addToCart,cart,remove} = itemStore

    let indexInCart;
    Favorites.forEach((cartItem, index) => {
        if(cartItem.slug === cartItem.slug) {
            indexInCart = index;
        }
    })
    let indexinCart;
    cart.forEach((item,index)=>{
        if(item.slug ===data.slug){
            indexinCart = index
        }
    })
    const removeItem = () => {
        removeToFavorites(Favorites[indexInCart].slug)
    }
    const removeIsCart = ()=>{
        remove(cart[indexinCart].slug)
    }
    const addItem = () => {

        addToCart(data);
    }
    let isInCart =!!cart.filter(item=>item.slug === data.slug).length;

    return (
        <div className={style.Favorites_list_item}>
            <div className={style.Favorites_list_content}>
                <Link style={{textDecoration:'none',cursor:"pointer"}} to={`/products/${data.categories.path}/${data.slug}`}>
                {data?.images.length === 0 ? <img width={93} height={93} src={place} alt=""/> : <img width={93} height={93} src={data.images[0].url} alt=''/>}
                </Link>
                <div className={style.Favorites_text}>
                    <Link style={{textDecoration:'none',cursor:"pointer",color:'#000000'}} to={`/products/${data.categories.path}/${data.slug}`}>
                    <div className={style.favor}>{data.name}</div>
                    </Link>
                    <div className={style.Favorites_text_button} >
                        {!isInCart ? <img  style={{cursor:"pointer"}} className={style.add_to_cart} onClick={addItem} src={add_to_cart} alt=""/>
                            :
                            <img  style={{cursor:"pointer"}} className={style.add_to_cart}  onClick={removeIsCart} src={add_to_cart_active} alt=""/>}
                        <i style={{cursor:'pointer'}} onClick={removeItem}><img src={trash} alt=""/></i>
                    </div>
                </div>
            </div>
            <div className={style.Favorites_list_amount}>

                <div className={style.Favorites_list_price}>{`${data.regular_price} ₽`} </div>
            </div>
        </div>
    );
};

export default observer(FavoritesItem);