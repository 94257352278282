import React, {useEffect, useState} from 'react';
import itemStore from "../../Store/ItemStore";
import style from "../../styles/liderSales.module.css";
import {Link, useParams} from "react-router-dom";
import add_to_wishlist from "../../icon/add_to_wishlist.png";
import add_to_cart from "../../icon/add_to_cart.png";
import add_to_cart_active from '../../icon/add_to_cart_active.png'
import add_to_wishlist_active from "../../icon/add_to_wishlist_active.png";
import place from '../../icon/placeholder.jpg'
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import {InView} from "react-intersection-observer";
import increm from "../../icon/PoligonLeft.png";
import decr from "../../icon/poligon.png";
import wishlist_active from "../../icon/add_to_wishlist_active.png";


const AllItem = ({data, path, setFetching, index}) => {


    const {addToCart, addToFavorites, Favorites, removeToFavorites, remove, cart, addToBrowsed,removeCounter,addToAmount} = itemStore

    const indexFavor = !!Favorites.filter(item => item.slug === data.slug).length;
    const indexCart = !!cart.filter(item => item.slug === data.slug).length;

    const addItem = () => {
        addToCart(data);
    }
    const pushInBrowsed = () => {
        addToBrowsed(data);
    }

    const addFavorites = () => {
        addToFavorites(data);
    }
    let indexRemoveIsCart;
    cart.forEach((item, index) => {
        if (item.slug === data.slug) {
            indexRemoveIsCart = index
        }
    })
    const removeIsCart = () => {
        remove(cart[indexRemoveIsCart].slug)
    }

    let indexRemove;
    Favorites.forEach((item, index) => {
        if (item.slug === data.slug) {
            indexRemove = index
        }
    })
    const removeFavor = () => {
        removeToFavorites(Favorites[indexRemove].slug)
    }

    //
    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (window.pageYOffset % 500 === 0) {
    //             // вызываем вашу функцию здесь
    //             console.log('Scroll position:', window.pageYOffset);
    //         }
    //     };
    //
    //     window.addEventListener('scroll', handleScroll);
    //
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);
    const  inputChange =(value) =>{
        if(+value > +cart[indexRemoveIsCart].stock_count){
            value = cart[indexRemoveIsCart].stock_count
        }


        if(+cart[indexRemoveIsCart].stock_count >= +value){
            addToAmount(data,+value)
        }
        // if(+value === 0){
        //     remove(cart[indexinCart].slug)
        // }

    }
    const blur = () =>{
        if(+cart[indexRemoveIsCart].amount === 0){
            addToAmount(data,cart[indexRemoveIsCart].amount = 1)
        }
    }

    useEffect(() => {
        if (path) {
            document.title = 'Электромир — ' + data?.categories.name;
        } else {
            document.title = 'Электромир — каталог товаров';
        }

    }, [data?.categories.name])
    const incr =()=>{
        removeCounter(cart[indexRemoveIsCart].slug)
    }
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    return (
        <>

            <>

                <div  className={style.liderSales_item}>
                    <div className={style.top}>
                    <div className={style.liderSales_wrap}>
                        <Link onClick={pushInBrowsed} className={style.link_img}
                              style={{textDecoration: `none`, cursor: "pointer"}}
                              to={`/products/${data.categories.path}/${data.slug}`}>
                            {data.images.length === 0 ? <img className={style.liderSales_img} src={place} alt=""/> :
                                <img className={style.liderSales_img} src={data.images[0]?.url} alt=''/>}

                        </Link>

                    </div>
                    <div className={style.liderSales_desc}>
                        <Link onClick={pushInBrowsed} className={style.link}
                              style={{textDecoration: `none`, cursor: "pointer"}}
                              to={`/products/${data.categories.path}/${data.slug}`}>
                            <div
                                className={style.liderSales_name}>{data.name}</div>
                            <div className={style.liderSales_price}>{data.regular_price === data.final_price ?`${data.regular_price} ₽` : <> <span style={{fontFamily: 'PT Sans',fontStyle:' normal',fontWeight: '700',fontSize:' 18px',lineHeight:' 23px',color: '#1437ad'}}>{data.final_price} ₽</span> / <span style={{textDecoration:'line-through', fontWeight:'500',fontSize:'14px'}}>{data.regular_price} ₽</span></>  }</div>
                        </Link>
                    </div>
                    </div>
                    <div className={ !indexCart ? style.liderSales_disc : style.liderSales_disc_open}>

                        <div className={ !indexCart ? style.liderSales_disc_container :  style.liderSales_disc_container_open} >
                            {!indexCart ? <img  style={{cursor:"pointer"}}  className={style.liderSales_cart} onClick={addItem} src={add_to_cart} alt=""/>
                                :
                                <img  style={{cursor:"pointer"}}  className={style.liderSales_cart}  onClick={removeIsCart} src={add_to_cart_active} alt=""/>}
                            <div className={!indexCart ? style.counter_hide : style.counter}>
                                <img src={increm} className={style.decr}  style={{cursor:"pointer"}} onClick={incr} ></img>
                                {/*<span>{cart[indexinCart]?.amount}</span>*/}
                                <input className={style.input_counter} min={1} onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} onBlur={blur}  onChange={(e)=>inputChange(e.target.value )}  placeholder='1' value={cart[indexRemoveIsCart]?.amount || '' } type="number"/>
                                <img src={decr} className={style.incr}  style={{cursor:"pointer"}} onClick={addItem} ></img>
                            </div>
                            {!indexFavor ? <img  style={{cursor:"pointer"}} className={style.liderSales_wish} onClick={addFavorites} src={add_to_wishlist} alt=""/>
                                :
                                <img style={{cursor:"pointer"}} className={style.liderSales_wish} onClick={removeFavor}  src={wishlist_active} alt=""/>
                            }
                        </div>
                    </div>
                </div>

            </>

        </>
    );

};

export default observer(AllItem);