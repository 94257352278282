import React, {useEffect, useState} from 'react';
import style from '../../styles/newProducts.module.css'
import {observer} from "mobx-react-lite";
import itemStore from "../../Store/ItemStore";
import {toJS} from "mobx";
import {Link} from "react-router-dom";
import NewProductsItem from "./NewProductsItem";
import Loader from "../Loader/Loader";
import ScrollContainer from 'react-indiana-drag-scroll'
const NewProducts = () => {

    const {NewData,getDataNew,categories,getDataCategories,isLoadingNew}=itemStore
    // const categoryArr =[{name:"Лампы",category:"lamps"},{name: "Провода",category: "wire"},{name: 'Распред.Коробки',category: "switchBox"},{name:"Светильники",category: "illuminator"}]
    const [activeIndex, setActiveIndex] = useState(0)
    // const [page,setPage] = useState(1)
    // const amountOfPages = NewData.length / 2;
    // const [isButtonDisabled, setIsButtonDisabled] = useState(false);


    const onClickBtn =(index)=>{
        setActiveIndex(index);
        const category = categories[index].path;

        getDataNew(category)
    }

    useEffect(() => {
        getDataNew();
        getDataCategories();

    }, [])




    return (
        <div className={style.newProduct}>
            <h1 className={style.newProduct_title}> Новые Товары</h1>
            <div className={style.newProduct_container}>

                <div className={style.newProduct_category}>
                    <ScrollContainer style={{display:"flex",gap:'30px'}}>
                    {categories.map((item, index) => {
                        return (

                            <button  style={{cursor:"pointer"}} key={index} onClick={()=>onClickBtn(index)}
                            className={style.newProduct_btn && (activeIndex === index) ? style['active'] : '' }>{item.name.length <=40 ? item.name :`${item.name.slice(0,40)}...`}</button>

                                )
                    })}
                    </ScrollContainer>
                </div >

                <div className={style.newProduct_container_item} >
                    {isLoadingNew ? [...new Array(4)].map((_,index)=><Loader key={index}/>) :
                    NewData.map((item,index)=>{
                        if(index<4){
                            return(
                              <NewProductsItem  key={item.slug} data={item}/>
                            )
                        }
                })}
                </div>

            </div>

        </div>
    );
};

export default observer(NewProducts);