import React from "react"
import ContentLoader from "react-content-loader"

const Loader = (props) => (
    <ContentLoader
        speed={2}
        width={227}
        height={128}

        viewBox="0 0 227 127"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="100" y="7" rx="0" ry="0" width="119" height="17" />
        <rect x="1" y="1" rx="0" ry="0" width="93" height="93" />
        <rect x="100" y="31" rx="0" ry="0" width="119" height="17" />
        <rect x="100" y="54" rx="0" ry="0" width="119" height="17" />
        <rect x="100" y="75" rx="0" ry="0" width="58" height="17" />
    </ContentLoader>
)

export default Loader
