import React from 'react';
import {Link} from "react-router-dom";
import style from "../../styles/category.module.css";

const BurgerLastChild = ({data}) => {
    return (
        <div className={style.burger_last}>
            <Link style={{textDecoration:"none",color: '#4E4E4E'}} to={`/catalog/${data.path}`}>
            {data.name}
            </Link>
        </div>
    );
};

export default BurgerLastChild;