import React, {useState} from 'react';
import styles from '../../styles/category.module.css'
import {toJS} from "mobx";
import {Link} from "react-router-dom";
import ModalLastChildCategory from "./ModalLastChildCategory";
const ModalChildCategory = ({data,active,setActive,reset}) => {
    const [activeChildsModal , setActiveChildModal] = useState(false)

    const [num,setNum] = useState(0)
    const [value,setvalue] = useState(null)
    const show =(id)=>{
        setNum(id)
        setActiveChildModal(true)
        setvalue(id)
    }
    const reset1 = () =>{
        setActiveChildModal(true)
        setvalue(null)
    }


    return (
        <>

        <div onMouseOut={reset1}   className={active === false ? styles.modal_childs : styles.modal_childs_active} >
            {data.child_categories?.map((item,index)=>{
                return(
                    <div key={item.name} onMouseOver={()=>show(index)} className={styles.child}>
                        {num === index  && item.child_categories.length !==0 ?   <ModalLastChildCategory  reset={reset1}  index={index}  active={activeChildsModal} setActive={setActiveChildModal}  data={item}/> : '' }
                    <Link key={item.name} style={{textDecoration:'none'}} to={`/catalog/${item?.path}`}>
                        <div style={{width: '350px' , position:'relative'}}>
                            <div onMouseOver={()=>show(index)}   className={styles.catalog_arrow}>
                                <div className={styles.childs_arrow}>
                                    {item.child_categories.length !== 0 ?   <li
                                                                                className={index  === value ? styles.catalog_li_active :  styles.catalog_li}></li> : ''}

                                </div>
                                <div className={styles.child_item}  onMouseOver={()=>show(index)} >{item?.name}</div>

                            </div>
                        </div>
                    </Link>
                </div>
                )
            })
            }
        </div>
        </>
    );
};

export default ModalChildCategory;