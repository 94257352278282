import React from 'react';
import img from '../../icon/Error.png'
const Error = () => {
    return (
        <div className='error_container'>

        <img src={img} className='error_img' ></img>
    <h1 className='error'>Страница не найдена. Попробуйте использовать поиск.</h1>
        </div>
    );
};

export default Error;